import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/entry.webpack';

import SpecialOffer from '../common/SpecialOffer.js';
import StateSelection from '../common/StateSelection.js';

class InstallationMaual extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            numPages: null,
            pageNumber: 1,
        };
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    prev() {
        if( this.state.pageNumber != 1 )
            this.setState({ pageNumber : this.state.pageNumber - 1 });
    }

    next() {
        if( this.state.pageNumber != this.state.numPages )
            this.setState({ pageNumber : this.state.pageNumber + 1 });
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        const { pageNumber, numPages } = this.state;

        return (
            <main className="carports" id="installation_maual">
                <div className="align-center">
                    <Document
                        file="/brochure.pdf"
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>
                        <i className="material-icons left-arrow" onClick={ () => this.prev() }>keyboard_arrow_left</i>
                        Page {pageNumber} of {numPages}
                        <i className="material-icons right-arrow" onClick={ () => this.next() }>keyboard_arrow_right</i>
                    </p>

                    {/* <button onClick={ () => this.prev() } className="left-arrow" aria-label="Left Arrow">
                        <i className="material-icons">keyboard_arrow_left</i>
                    </button>
                    
                    <button onClick={ () => this.next() } className="right-arrow" aria-label="Right Arrow">
                        <i className="material-icons">keyboard_arrow_right</i>
                    </button> */}
                </div>

                {/* <SpecialOffer /> */}

                <StateSelection />
            </main>
        );
    }
}

export default InstallationMaual;
