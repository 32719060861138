import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';

const sortedGalleryData = [];
 
class Disclosures extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            weeklySpecials: false,
            weeklyFName: "",
            weeklyLName: "",
            weeklyPhone: "",
            weeklyEmail: "",
            weeklyState: "",
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    responsive = {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
    };

    sliderItems = [ 
        [slider_1, 'Wedding Barn', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Boat Dock Cover', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Display Lot', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Cafe Table Cover', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'],
        [slider_1, 'Wedding Barn', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'] 
    ];

    render() {
        // Sorting The Gallery Data
        // sortedGalleryData.length = 0;
        // productCategories.map((item) => {
        //     Object.keys(galleryData).map((key) => {
        //         if( item == "carports" && galleryData[key]['category'] == "carports" ) {
        //             sortedGalleryData.push( galleryData[key] );
        //         } else if( item != "carports" && galleryData[key]['category'] == item ) {
        //             sortedGalleryData.push( galleryData[key] );
        //         }
        //     })
        // })

        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        // const stateImg = '/images/states/' + state + '.jpg';

        // const sliderItemsHtml = sortedGalleryData.map(this.sliderItem);

        return (
            <main id="disclosures" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>Disclosures</h2>

                    <div>
                        <p>Carports.com nor any of the other sponsors, contributors, make any representations or warranties regarding the accuracy, reliability, completeness, currency or usefulness of any content contained in or linked from this web site, any give-a-ways, any contests, etc.</p>

                        <br />

                        <p>Content may include inaccuracies or typographical errors.  Changes are periodically made to the information herein.  In all events, you - and not Carports.com or any of the affiliates or partners - bear complete responsibility for any reliance on such content.  Nothing herein should be construed as providing legal advice of any kind!</p>

                        <br />

                        <p className="color-red">Questions, issues or comments concerning our disclaimers & legal information, terms & conditions and rules & regulations should be directed in writing at:</p>

                        <br />

                        <p className="mb-0">T-N-T Carports, Inc.</p>
                        <p className="mb-0">c/o Carports.com</p>
                        <p className="mb-0">PO BOX 351</p>
                        <p className="mb-0">White Plains, North Carolina  27031 USA</p>
                    </div>

                    <hr />

                    <div>
                        <br />

                        <div className="overflow-hidden">
                            <span className="float-left"><b>Effective Date:</b> July 12, 2004</span>
                            <span className="float-right"><b>Last UpDated:</b> August 14, 2020</span>
                        </div>

                        <br />

                        <p>Thank you for checking our Legal and Disclaimer information.  We will try to make this information brief and to the point.  We're in the business to build a structure for you, not legal mumble-jumbo!  </p>

                        <br />
                    </div>

                    <hr />

                    <div>
                        <h4 className="color-red">General Disclaimer & Terms and Conditions</h4>

                        <br />

                        <p>The bottom line is that you'll know the bottom line. We build the exact carport you want and you know exactly how much you'll pay for it.  The pricing on the carport configurator may vary, we hold no responsibility or represent any obligations for any possible technical problems and/or errors in pricing.  Once your carport order is placed through the carport configurator, it will be processed pending approval from management, until then we have the right to refuse any order for any reason and also hold the right to change pricing at any time prior to order confirmation.  (example:  if you get a carport estimate, the next day the price may be different if we have a sale or price increase)  It's not likely that there will be any differences, this is purely our disclaimer just in case.</p>

                        <br />

                        <p>Orders through manufactures must be confirmed by the manufacturers district office before being accepted.  Carports.com will notify customers within 72 hours of placing order if there are any pricing differences or bracing recommendations.  In some cases there many be differences in pricing due to some areas requiring extra bracing.  This rarely happens but it is a possibility.  Rest assured that customer's interest is priority.  In order to better server it's customers, Carports.com contact the manufacturer's district office (according to local order placed) to confirm pricing and order details within 24 hours of order being taken incase there are any pricing differences of the special order item.</p>

                        <br />

                        <p>Carports.com owns and operates two online estimators.  The Installed-Units Estimator excludes state and local sales taxes.  Taxes are applicable to all units that are delivered and installed sales because the manufacturer delivers and installs the product for you.  </p>

                        <br />
                    </div>

                    <hr />

                    <div>
                        <h4 className="color-red">Returns, Cancellations and Refund Policy</h4>

                            <p><span className="color-blue font-20">Policy for products:</span> T-N-T Carports, T-N-T Metal Buildings, American Steel Carports, American Carports, Coast-to-Coast Carports</p>  		

                            <p>In order to be very clear with all customers, we go over our policy throughout several steps of our ordering process.</p>

                            <div className="pl-10">
                                <p className="mb-0"><b>1.</b> When the order is placed, verbally by telephone.</p>
                                <p className="mb-0"><b>2.</b> All order calls are recorded and placed on file for any question.</p>
                                <p className="mb-0"><b>3.</b> At the bottom of every page of our website, under Disclosures & Legal.</p>
                                <p className="mb-0"><b>4.</b> In the emailed order receipt, detailing our policies, which we retain a carbon-copy
                    of to keep as proof that customers receive the order receipt.</p>
                            </div>

                            <br />

                            <p>OUR REFUND POLICY:</p>

                            <p>Carports.com, does not offer a refund because every order placed is a special order.   When ordering, your sales person recorded the confirmation on the phone when you agreed to our no refund policy, which reads, </p>
                            
                            <br />
                            
                            <p>“This portion of the call today will be recorded and kept on file for future reference.  The order you are placing today may be canceled.  If you do cancel, your initial down payment that you are putting towards your order today is not refundable.  The reason the down payment is non refundable is because it goes towards the preparation, paperwork and processing of your order today.  It is our customer’s responsibility to know if they need a permit or not before they place their order. In the event that you do cancel the order you are putting in today, the down payment will be lost since it is used towards the preparation, paperwork and processing of your order today. By ordering today, you are agreeing to our policy.”  </p>

                            <p>This policy is recorded digitally and stored, and signed off my a manager on duty along with the sales person taking the order.</p>

                            <p>There are absolutely no exceptions to our refund policy.  It is up to you to work with the manufacturer to setup delivery, installation, etc.  At no time is the sales office (Carports.com) responsible for unexpected circumstances due to manufacturer.  If circumstances arise, it is the responsibility of the customer to work that out with the manufacturer solely, not Carports.com.  Even if the customer cancels the order and refuses delivery and/or installation, our services preformed are for the preparation, paperwork and processing of the order, not towards the product itself.</p>

                            <p>In order to show good faith with our refund policy, Carports.com will retain a store credit for the amount of down payment paid at the time of order.  That store credit may be used towards the future down payment of a future order placed with our company.  The store credit must be used within three (3) years or the credit will be forfeited. </p>

                            <p>Carports.com is not responsible for any manufacturers guarantees, warranties, installation issues, delivery times, and any other unforeseen problem(s) that could arise throughout the process of delivery, shipping and/or installation.  Carports.com will work in good faith with the customer and the manufacturer to assist in resolving issues if any develop as long as both parties are giving reasonable and conscious effort to resolve the issue.  Carports.com take great pride in selling the our featured brands but are only hold responsibilities of a "dealer" for these manufacturers.  As a dealer, Carports.com resolves the right to hold down payment(s) made to Carports.com to be non-refundable because the down payment is considered for the preparation, paperwork and processing of your order, not towards the actual product itself.</p>
                    </div>

                    <hr />

                    <div>
                        <h4 className="color-red">Shipping and Claims Policy & Terms and Conditions</h4>

                        <p>If your order at Carports.com or affiliated companies requires shipping through a third party shipping company, we will assist you through the process.  The following terms and conditions apply to all orders that are shipped via Carports.com's shipping department.</p>

                        <p>Upon placing your order, you will be responsible for paying for an estimated freight quote based on the current rates and fuel surcharges.  You will receive a notification via email when your order has been shipped from the warehouse facility.  It is not up to Carports.com when your order is shipped and Carports.com is not responsible for the time frame it takes to have your order ready to ship from the date you place your order, nor from the time it takes to get your shipment from the date it ships from the warehouse.</p>

                        <p>At the time you receive your shipment, Carports.com recommends for all customers to inspect all pieces of the shipment inside and out to check for possible damages and missing parts.  If damages are suspected, write on the bill of laden (shipping document) that the trucking company provides you to sign to accept delivery that the shipment has possible damages or missing parts.  In order to have a successful insurance claim so that we can replace your parts without any delays, this is required.  There are no exceptions.  There is no way for us to guarantee replacement of damaged or missing parts if the shipping documents are not marked as damaged/missing at the time of delivery while the trucking company is still present.  At the time you mark damaged/missing parts on the bill of laden, you need to contact our customer service department at (336)-372-9180, Extension 210 to notify Carports.com of the claim.  You will be asked to make a full inventory of the parts you received and fax them to (404)-601-0233.  We will review your documents, contact the appropriate parties and notify you of the next steps.</p>

                        <p>If properly documented at the time the shipment is received, replacement(s) typically are made top priority over all other orders.  Customer service for our customers is our top priority at all times!   </p>
                    </div>

                    <hr />

                    <div>
                        <h4 className="color-red">General Website Information</h4>

                        <br />

                        <h5>Terms of Use</h5>

                        <p>The information in this site is protected by copyright. You may only use the information, text or graphics contained in this site for your personal use and may not reproduce, adapt or publish it, in whole or in part, for any purpose without the express written consent of Carports.com. The Carports.com names and logos, are trademarks of T-N-T Carports, Inc. and these logos may not be used, downloaded, copied or distributed in any way without prior written permission.</p>

                        <h5>Limitation on Scope of Content</h5>

                        <p>This web site contains information regarding its products and promotional programs. The Carports.com products described on this site contain uniquely American specifications and equipment and are offered for sale within and outside the U.S.A. (excluding some states). The promotional programs described on this site are available only in those states and territories of the U.S. (excluding some states) as specifically referred to within the description of the program. All pricing information referred to on this site is in U.S. dollars. </p>

                        <p>This policy may be modified from time to time; the date of the most recent revisions will appear at the top of this page, so please visit us frequently.</p>

                        <h5>No Representation or Warranty</h5>

                        <p>Carports.com reserves the right to modify the information contained on this site at any time without notice. While Carports.com makes all reasonable efforts to ensure that all material on this site is correct, accuracy cannot be guaranteed and Carports.com DOES NOT assume any responsibility for the accuracy, completeness or authenticity of any information contained on this site. This site and all information and materials contained herein, is provided to you "as is" without warranty of any kind.</p>

                        <h5>Hypertext Links to External Sites</h5>

                        <p>This site may contain hypertext links to other World Wide Web sites, which are completely independent of this site. Carports.com makes no representation or warranty as to the accuracy, completeness or authenticity of the information contained in any such hypertext link, and any hypertext link to another person or entity shall not in any manner be construed as endorsement by Carports.com of such person's or entity's World Wide Web site, products or services. Your linking to any other off-site pages or other sites is at your own risk.</p>

                        {/* <p className="color-red"><b>Policies Customer Agree To By Accepting Order with Carports.com:</b></p> */}

                        {/* <ul className="bottom-nav">
                            <li>Warranty Information</li>
                            <li>|</li>
                            <li>Return Policy</li>
                            <li>|</li>
                            <li>Privacy Policy</li>
                            <li>|</li>
                            <li>Disclosures & Legal</li>
                        </ul> */}
                    </div>

                </div>
            </main>
        );
    }
}

export default Disclosures;
