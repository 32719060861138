import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';

const sortedGalleryData = [];
 
class ReturnPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="disclosures" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>Return Policy</h2>

                    <div>
                        <h4>TNT Carports, Inc., TNT Metal Buildings, Inc., American Steel Carports, Inc., American Carports, Inc. and Coast to Coast Carports, Inc., also known as "INSTALLED UNIT COMPANY"</h4>

                        <p>Because all INSTALLED UNIT orders are special order, once the order has been placed, there is a <span className="underline">no cancellation and no refund and no return policy.</span></p>

                        <p>Once customer's order is placed with INSTALLED UNIT COMPANY, it will be delivered and installed within an average of three to eight weeks. It is made clear with all customers that this is an “average” and there is no way for Carports.com to guarantee a delivery or installation date for INSTALLED UNIT COMPANY products because it varies depending on the installation crew, transportation availability, weather, schedules, readily available inventory, and other possible unforeseen reasons. This is normal for this industry. When ordering through Carports.com, you can rest assured that you will get your order as soon as possible.</p>

                        <p>Carports.com is not responsible for the installation nor delivery for INSTALLED UNIT COMPANY orders.  Customers who place an order with Carports.com put a 10% down payment on the order, which is not refundable for any reason. Carports.com encourages all customers to research products, local area building codes, permitting and other possibilities before placing their order to prevent unforeseen issues.  It is the responsibility of the customer to work with INSTALLED UNIT COMPANY to successfully schedule a delivery date according to the customers and INSTALLED UNIT COMPANY's schedules.  The customer is also responsible for resolving any issues that may arise directly with INSTALLED UNIT COMPANY and not hold Carports.com responsible for any manufacturers guarantees, warranties, installation issues, delivery times, and any other unforeseen problem(s) that could arise throughout the process.  Carports.com will work in good faith with the customer and INSTALLED UNIT COMPANY to assist in resolving issues if any develop as long as both parties are giving reasonable and conscious effort to resolve the issue.  Carports.com takes great pride in selling INSTALLED UNIT COMPANY but are only hold responsibilities of a "dealer" for INSTALLED UNIT COMPANY.  As a dealer, Carports.com resolves the right to hold down payment(s) made to Carports.com to be non-refundable.  In the event the customer wishes to cancel the order placed with INSTALLED UNIT COMPANY, the 10% down payment will not be refunded to the customer unless otherwise agreed by Carports.com senior management.</p>
                    </div>
                </div>
            </main>
        );
    }
}

export default ReturnPolicy;
