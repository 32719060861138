import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';

import videoData from '../../assets/data/videos.json';
import vidIcon from '../../assets/imagesSlow/defaults-blue_16x16.png';
import vidIconn from '../../assets/imagesSlow/video-icon-1.png';
import vidIconn2 from '../../assets/imagesSlow/video-icon-2.png';

class Videos extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            mouseEnter  : false,
            modal: false,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    video_mouse_enter(key) {
        this.setState(prevState => {
            return { mouseEnter: { ...prevState.mouseEnter, [key]: true } };
        });
    }

    video_mouse_leave(key) {
        this.setState(prevState => {
            return { mouseEnter: { ...prevState.mouseEnter, [key]: false } };
        });
    }

    open_video(selectedKey) {
        this.setState({ modal : !this.state.modal });

        Object.keys(videoData).map((key) => {
            if( key == selectedKey ) 
                this.setState({ selectedVideo : videoData[key]["url"] });
        })
        
    }

    toggle() {
        this.setState({ modal : !this.state.modal });
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="disclosures" className="docs">
                <div className="container mt-30 mb-50 videos-section">
                    <h2 className="pl-20 pr-20">Our Videos</h2>

                    <div className="row">
                        {
                            Object.keys(videoData).map((key) => {
                                return(
                                    <div key={`${key}`} className="item col-md-3 col-sm-6">
                                        <div className="video" 
                                            onMouseEnter={ () => this.video_mouse_enter(key) } 
                                            onMouseLeave={ () => this.video_mouse_leave(key) }
                                        >
                                            <a onClick={ () => this.open_video(key) }>
                                                <img className="video-img" src={ videoData[key]["img"] } alt="Video" />
                                            </a>
                                            <img className={ this.state.mouseEnter[key] ? "video-icon-1" : "hidden" } src={ vidIconn } alt="Video" />
                                            <img className={ this.state.mouseEnter[key] ? "video-icon-2" : "hidden" } src={ vidIconn2 } alt="Video" />
                                        </div>
                                        
                                        <div className="content">
                                            <h6>{ videoData[key]["title"] }</h6>
                                            <p><img src={ vidIcon } alt="Icon" />{ videoData[key]["sub_title"] }</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <Modal isOpen={ this.state.modal } toggle={ () => this.toggle() } >
                        <ModalBody className="modal-body">
                            <ReactPlayer url={ this.state.selectedVideo } playing loop />
                        </ModalBody>
                    </Modal>
                </div>
            </main>
        );
    }
}

export default Videos;
