import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Modal, ModalBody } from 'reactstrap';

import states from '../../assets/data/states.json';

const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

class OnlineBuild extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state : "North Carolina",
            isAgree: true,
            firstname: "",
            lastname: "",
            lastname: "",
            phone: "",
            email: "",
            zipcode: "",
            onlineBuildModal: false,
            validEmail: true,
            validPhoneNumber: true,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit( event ) {
        event.preventDefault();

        if( !this.validatePhoneNumber() || !this.validateEmail() ) {
            return;
        }

        if( !this.refs.terms_checkbox.checked )
            this.setState({ isAgree : false });
        else {
            let state = "";
            if( this.state.registerState ) {
                state = this.state.registerState;
            } else {
                if( window.state )
                    state = window.state;
                else 
                    state = this.state.state;
            }

            const params = 'firstname=' + this.state.firstname + '&lastname=' + this.state.lastname + 
                '&email=' + this.state.email + '&phone=' + this.state.phone + '&state=' + state + 
                '&zipcode=' + this.state.zipcode;
            window.open('https://carports.net/pricing_system/add_entry_new2.php?' + params, '_self');
        }
    }

    validateEmail() {
        if (/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test( this.state.email )) { 
            this.setState({ validEmail : true });
            return true;
        } else {
            this.setState({ validEmail : false });
            return false;
        }
    }

    validatePhoneNumber() {
        if ( (this.state.phone).length !== 14 ) {
            this.setState({ validPhoneNumber : false });
            return false;
        } else {
            this.setState({ validPhoneNumber : true });
            return true;
        }
            
    }

    toggle() {
        window.onlineBuildModal = false;
        this.setState({ onlineBuildModal : false });
    }

    handlePhoneNumber({ target: { value } }) {  
        this.setState( prevState=> ({ phone: normalizeInput(value, prevState.phone) }) );
    };

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        // Using The Selected State
        let onlineBuildModal = "";
        if( window.onlineBuildModal )
            onlineBuildModal = window.onlineBuildModal;
        else
            onlineBuildModal = this.state.onlineBuildModal;
        
        return (
            <main>
                <Modal isOpen={ onlineBuildModal } toggle={ () => this.toggle() } >
                    <ModalBody className="modal-body">
                       
                        <form onSubmit={this.handleFormSubmit} >
                            <div className="row">
                                <div className="col-md-12 align-center">
                                    <h6 className="mb-20"><b>Register Now To Get Pricing</b></h6>
                                </div>
                                
                                <div className="col-md-6 pr-5">
                                    <input type="text" id="fname" name="firstname" placeholder="First Name"
                                        value={this.state.firstname} onChange={ e => this.setState({ firstname: e.target.value }) } />
                                </div>

                                <div className="col-md-6 pl-5">
                                    <input type=" text" id="lname" name="lastname" placeholder="Last Name"
                                        value={this.state.lastname} onChange={ e => this.setState({ lastname: e.target.value }) } />
                                </div>
                            
                                <div className="col-md-12">
                                    <input type="text" id="phone" name="phone" placeholder="Phone Number"
                                        className={ this.state.validPhoneNumber ? "" : "invalid" }
                                        value={this.state.phone} onChange={ (e) => this.handlePhoneNumber(e) } />
                                    <p className={ this.state.validPhoneNumber ? "hidden" : "invalid" }>
                                        Invalid Phone Number. Ex: (555) 555-5555
                                    </p>
                                </div>

                                <div className="col-md-12">
                                    <input type="text" id="email" name="email" placeholder="Email" 
                                        className={ this.state.validEmail ? "" : "invalid" }
                                        value={this.state.email} onChange={ e => this.setState({ email: e.target.value }) } />
                                    <p className={ this.state.validEmail ? "hidden" : "invalid" }>Invalid Email</p>
                                </div>

                                <div className="col-md-12">
                                    <select className="pl-10" name="state" onChange={ e => this.setState({ registerState: e.target.value }) }>
                                    {
                                        states.map((item, i) => {
                                            return (
                                                <option key={`key-${i}`} value={ item[0] } selected={ item[0] == state ? "selected" : "" } >
                                                    { item[0] }
                                                </option>
                                            )
                                        })
                                    }
                                    </select>
                                </div>

                                <div className="col-md-12">
                                    <input type="text" id="zipcode" name="zipcode" placeholder="Zip Code"
                                        value={this.state.zipcode} onChange={ e => this.setState({ zipcode: e.target.value }) } />
                                </div>

                                <div className="col-md-12">
                                    <p className="mb-5 font-12">Save Quotes, See structure in 3d with price, compare designs and log in to view saved quotes.</p>
                                </div>

                                <div className="col-md-12">
                                    <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" ref="terms_checkbox" defaultChecked />
                                    <label htmlFor="styled-checkbox-2">Agree to <a href="/disclosures" target="new">Terms and Conditions</a></label>
                                </div>

                                <div className="col-md-12">
                                    <input type="submit" className="submit-btn mb-20" value="START QUOTING NOW" />
                                </div>

                            </div>
                        </form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={ !this.state.isAgree } toggle={ () => this.setState({ isAgree : !this.state.isAgree }) } >
                    <ModalBody className="modal-body">
                        <div className="agree-alert">You must agree to our terms to use our customized quoting tool.</div>                
                    </ModalBody>
                </Modal>
            </main>
        );
    }
}

export default OnlineBuild;
