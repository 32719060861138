import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import OnlineBuild from './OnlineBuild';

class FinanceAndCoupon extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
        };

    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <div className="finance-weekly-section container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="tel:1-888-822-7767">
                            <div className="finance">
                                <div className="top">WE</div>

                                <div className="bottom">FINANCE</div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6">
                        <div className="weekly" 
                            onClick={ () => this.openOnlineBuildModal() }
                        >
                            <div className="top">CLICK HERE FOR</div>
                            
                            <div className="middle">
                                <div className="left">SAVE</div>

                                <div className="center">
                                    <span className="round-1"></span>
                                    <span className="round-2"></span>
                                    <span className="round-3"></span>
                                    <span className="round-4"></span>
                                    <span className="round-5"></span>
                                    <span className="round-6"></span>

                                    <label>$</label>
                                </div>
                                
                                <div className="right">BIG</div>
                            </div>
                            
                            <div className="bottom">WEEKLY COUPONS</div>
                        </div>
                    </div>
                </div>

                <OnlineBuild />

            </div>
        );
    }
}

export default FinanceAndCoupon;
