import React from 'react';
import { Link } from 'react-router-dom';


export default class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="container">
					<div className="top sec-action call-to-action-area">
						<div className="row">
							<div className="col-sm-9">
								<div className="action-content">
									<h2>Looking for tube metal parts only?</h2>
									<p>Call 1-888-822-7767 Now! </p>
								</div>
							</div>
							
							<div className="col-sm-3">
								<div className="purchase-link text-right">
									<a className="themeix-purchase-btn-3" href="/installation_maual" target="_blank">View Brochure</a>
								</div>
							</div>
						</div>
					</div>

                    <div className="center row">
					    <div className="col-sm-6 col-md-3">
                            <div id="custom_social_dewidget-1" className="widget widget-block footer-widget single-footer widget_custom_social_dewidget">
                                <div className="footer-heading-wrap">
                                    <span className="heading-icon">
                                        <i className="fa fa-tasks" aria-hidden="true"></i>
                                    </span>
                                    <h3 className="footer-heading">About Us</h3>
                                </div>
                                
                                <div className="single-footer-text">
                                    <p>From our state of the art tubing mill to your property. Trust us for all of your metal building needs &amp; parts!</p>
									<Link to="/Aboutus">
										<p>Learn More</p>
									</Link>
                                </div>
                                
                                <div className="social-links">
                                    <ul>
                                        <li>
											<label>
                                            <a href="https://www.facebook.com/TubeMetalShop/" rel="noopener" target="_blank" aria-label="facebook" >
												<i className="fa fa-facebook"></i>
											</a>
											</label>
										</li>
										<li>
											<a href="https://twitter.com/tube_metal_shop" rel="noopener" target="_blank"aria-label="twitter">
												<i className="fa fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="https://plus.google.com/u/0/111217556687780232461" rel="noopener" target="_blank" aria-label="google plus">
												<i className="fa fa-google-plus"></i>
											</a>
										</li>
										<li>
											<a href="https://vimeo.com/user83930212" target="_blank" rel="noopener" aria-label="vimeo">
												<i className="fa fa-youtube"></i>
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/tube_metal_shop/" target="_blank" rel="noopener" aria-label="vimeo">
												<i className="fa fa-instagram"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-sm-6 col-md-3">
							<div id="text-1" className="widget widget-block footer-widget single-footer widget_text">
								<div className="footer-heading-wrap">
									<span className="heading-icon">
										<i className="fa fa-tasks" aria-hidden="true"></i>
									</span>
									<h3 className="footer-heading">Shop By Picture</h3>
								</div>			
								
								<div className="textwidget">
									<ul>
										<li><a href="/carports">Carports</a></li>
										<li><a href="/barns">Barns</a></li>
										<li><a href="/lean_tos">Lean-to</a></li>
										<li><a href="/rv_covers">Rv Covers</a></li>
										<li><a href="/run_in_sheds">Run-in Sheds</a></li>
										<li><a href="/extra_wide">Extra Wide</a></li>
										<li><a href="/customize">Customized</a></li>
									</ul>
                            	</div>
							</div>
						</div>
						
						<div className="col-sm-6 col-md-3">
							<div id="text-2" className="widget widget-block footer-widget single-footer widget_text">
								<div className="footer-heading-wrap">
									<span className="heading-icon">
										<i className="fa fa-tasks" aria-hidden="true"></i>
									</span>
									<h3 className="footer-heading">Get Support</h3>
								</div>			
								
								<div className="textwidget">
									<ul>
										<li><Link to={{ pathname:"/disclosures", data: window.state }}>Disclosures & Legal</Link></li>
										<li><Link to={{ pathname:"/disclaimer", data: window.state }}>Disclaimer</Link></li>
										<li><Link to={{ pathname:"/coupons", data: window.state }}>Coupons</Link></li>
										<li><Link to={{ pathname:"/comparison", data: window.state }}>Comparison</Link></li>
										<li><Link to={{ pathname:"/return_policy", data: window.state }}>Return Policy</Link></li>
										<li><Link to={{ pathname:"/color_charts", data: window.state }}>Color Charts</Link></li>
										<li><Link to={{ pathname:"/faq", data: window.state }}>FAQ</Link></li>
										<li><Link to={{ pathname:"/testimonials", data: window.state }}>Testimonials</Link></li>
										<li><Link to={{ pathname:"/videos", data: window.state }}>Videos</Link></li>
										<li><Link to={{ pathname:"/installation_maual", data: window.state }}>Brochure</Link></li>
									</ul>
                            	</div>
							</div>
						</div>
						
						<div className="col-sm-6 col-md-3">
							<div id="text-3" className="widget widget-block footer-widget single-footer widget_text">
								<div className="footer-heading-wrap">
									<span className="heading-icon">
										<i className="fa fa-tasks" aria-hidden="true"></i>
									</span>
									<h3 className="footer-heading">Contact</h3>
								</div>			
								
								<div className="textwidget">
								<p>20 shops nation wide positioned for delivery, pick up or installation. </p><br></br>
									<p>(Corporate office) <br></br>170 Hollysprings Rd Mount Airy, N.C 27030</p>
									
									<ul>
										<li><a href="tel:+18888227767">T : +1 888 822 7767</a></li>
										
										<li><a href="mailto:sitewebforms@carports.com">E : sales@carports.com</a></li>
									</ul>
                            	</div>
							</div>
						</div>                        
                    </div>

                </div>

				<div className="bottom">
					<div className="col-sm-12">
						<div className="footer-bottom">
							<div className="footer-links">

							</div>
							<div className="copyright-text">
								<p>© Carports.com  | All rights reserved.</p>
							</div>							
						</div>
					</div>
				</div>	
                 
            </div>
        )
    }
}