import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';

import galleryData from '../../assets/data/gallery.json';

class PictureLookupTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            modal: false,
            productNumber: "",
            state: "North Carolina",
            isProduct: false,
        };

    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        let isProduct = false;

        return (
            <div className="product-search align-center mt-50">
                <h4 className="pr-20"><font color="#3694F4">Picture Lookup Tool</font></h4>
                <input id="product_search_input" placeholder="Enter Picture Number" aria-label="enter picture number"
                    value={this.state.productNumber} onChange={ e => this.setState({ productNumber: e.target.value }) } />
                <button aria-label="search images" onClick={ () => this.setState({ modal: !this.state.modal }) } className="search-btn">SEARCH</button>

                <Modal isOpen={ this.state.modal } toggle={ () => this.setState({ modal: !this.state.modal }) } className="product-lookup-tool" >
                    <ModalBody className="modal-body">
                    {
                        Object.keys(galleryData).map((key) => {
                            if( this.state.productNumber == galleryData[key]['number'] ) {
                                const image = galleryData[key]['image'];
                                isProduct = true;

                                return (
                                    <div key={ `${key}` } className="col-md-6">
                                        <div className="item">
                                            <img src={ `${image}` } alt={ galleryData[key]['category'] } />
                                            <p className="align-center mt-10">Image - { galleryData[key]['number'] }</p>

                                            <div className="title-div">
                                                <span className="title float-left">{ galleryData[key]['title'] }</span>
                                                <span className="price float-right">{ galleryData[key]['price'][state] }</span>
                                            </div>

                                            <div className="description">
                                                { galleryData[key]['description'] }
                                            </div>

                                            <div className="button-div align-right">
                                                {/* <button onClick={ () => this.open_modal(i, "Buy Now") }>BUY NOW</button>
                                                <button onClick={ () => this.open_modal(i, "Email Quote") }>EMAIL QUOTE</button> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                       
                    }
                    {
                        !isProduct ? ( <p className="no-product-msg">It looks like there's nothing that matches this number. Please try again.</p> ) : ("")
                    }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default PictureLookupTool;
