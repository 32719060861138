import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';

class SpecialOffer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
        };

    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <div className="special-offer container mb-50 mt-50">
                <div className="section-title">
                    <h4>Special <span>Offer</span></h4>
                    <p>
                        Order this week and get the weather blocker sides and solar light add in. It’s free! You just pay shipping and handling. These are great accessories and you can get it all with buying direct from the factory
                    </p>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <img src={ require('../../assets/images/01Weatherblocker.webp') } alt="Special Offer" />
                        <h5>Weather Blocker Sides In Use</h5>
                    </div>

                    <div className="col-md-4">
                        <img src={ require('../../assets/images/weatherBlockerSample.webp') } alt="Special Offer" />
                        <h5>Close Up Weather Blocker</h5>
                    </div>

                    <div className="col-md-4">
                        <img src={ require('../../assets/images/solar-light-gs-5.webp') } alt="Special Offer" />
                        <h5>Solar Light</h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpecialOffer;
