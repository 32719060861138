import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

import tntlogo from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogofb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import tntMetalLogo from '../../assets/images/Disclaimer/tnt-metal-buildings.webp';
import tntMetalLogofb from '../../assets/imagesSlow/Disclaimer/tnt-metal-buildings.bmp';
import triLogo from '../../assets/images/Disclaimer/tristate.webp';
import triLogofb from '../../assets/imagesSlow/Disclaimer/tristate.bmp';
import westLogo from '../../assets/images/Disclaimer/westcoast.webp';
import westLogofb from '../../assets/imagesSlow/Disclaimer/westcoast.bmp';
import amerLogo from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amerLogofb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import coastLogo from '../../assets/images/Disclaimer/coast-to-coast.webp';
import coastLogofb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import interLogo from '../../assets/images/Disclaimer/interstate.webp';
import interLogofb from '../../assets/imagesSlow/Disclaimer/interstate.bmp';

import states from '../../assets/data/states.json';
import OnlineBuild from '../common/OnlineBuild.js';
 
class Comparison extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state : "North Carolina",
            buildModal: false,
            buildFName: "",
            buildLName: "",
            buildPhone: "",
            buildEmail: "",
            buildState: "",
            isAgreeBuild: true,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    change_state(event) {
        if( event.target.value != "" ) {
            this.setState({ buildState : event.target.value });
            this.setState({ buildModal : !this.state.buildModal });
        }
    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="comparison" className="docs">
                <div className="container mt-30 mb-50">
                    <h2 className="align-center">SIDE BY SIDE VIEW OF THREE BRANDS, &nbsp;
                        <a onClick={ () => this.openOnlineBuildModal() } className="skip-to-pricing">
                            <mark>SKIP TO PRICING...</mark>
                        </a>
                    </h2>

                    <div className="brand-cols">
                        <div className="col">
                            <div>
                             <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                               

                                <ul>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        CERTIFIED & ENGINEERED Metal Structures
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Carports, Enclosed Carports, Garages, Barns, RV Covers, Boat Covers, Utility Carports, and more...
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Crushed Bend
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        14 Gauge, 2 ½ x 2 ½ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        12 Gauge, 2 ¼ x 2 ¼ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Low Cost with Quality Workmanship
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        3 Ft Ground Anchors Standard, no extra!
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Meets Ground Snow and Wind Loads
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Free Delivery Included
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Engineering Plans Available Ask A Sales Person
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        Galvanized steel tubing resistant to rust, fire, termites and rot.
                                    </li>
                                    <li>
                                        <i className="material-icons warning-color">check</i>
                                        20 Year Limited Warranty Ask A Sales Person
                                    </li>
                                </ul>

                                <p className="align-center font-12 padding-0-5">
                                    <b><span className="danger-color underline">Get instant pricing</span> online for the size structure you are looking for...</b>
                                </p>

                                <div className="align-center">
                                    <button onClick={ () => this.openOnlineBuildModal() }>Build it online!</button>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col">
                            <div>
                                <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />

                                <ul>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        CERTIFIED & ENGINEERED Metal Structures
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Carports, Enclosed Carports, Garages, Barns, RV Covers, Boat Covers, Utility Carports, and more...
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Crushed Bend
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        14 Gauge, 2 ½ x 2 ½ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        12 Gauge, 2 ¼ x 2 ¼ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Low Cost with Quality Workmanship
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        3 Ft Ground Anchors Standard, no extra!
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Meets Ground Snow and Wind Loads
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Free Delivery Included
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Engineering Plans Available Ask A Sales Person
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        Galvanized steel tubing resistant to rust, fire, termites and rot.
                                    </li>
                                    <li>
                                        <i className="material-icons danger-color">check</i>
                                        20 Year Limited Warranty Ask A Sales Person
                                    </li>
                                </ul>

                                <p className="align-center font-12 padding-0-5">
                                    <b><span className="danger-color underline">Get instant pricing</span> online for the size structure you are looking for...</b>
                                </p>

                                <div className="align-center">
                                    <button onClick={ () => this.openOnlineBuildModal() }>Build it online!</button>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div>
                                 <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />

                                <ul>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        CERTIFIED & ENGINEERED Metal Structures
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Carports, Enclosed Carports, Garages, Barns, RV Covers, Boat Covers, Utility Carports, and more...
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Crushed Bend
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        14 Gauge, 2 ½ x 2 ½ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        12 Gauge, 2 ¼ x 2 ¼ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Low Cost with Quality Workmanship
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        3 Ft Ground Anchors Standard, no extra!
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Meets Ground Snow and Wind Loads
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Delivery Included
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Engineering Plans Available Ask A Sales Person
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Galvanized steel tubing resistant to rust, fire, termites and rot.
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        20 Year Limited Warranty Ask A Sales Person
                                    </li>
                                </ul>

                                <p className="align-center font-12 padding-0-5">
                                    <b><span className="danger-color underline">Get instant pricing</span> online for the size structure you are looking for...</b>
                                </p>

                                <div className="align-center">
                                    <button onClick={ () => this.openOnlineBuildModal() }>Build it online!</button>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col">
                            <div>
                                 <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Coast to Coast logo" />

                                <ul>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        CERTIFIED & ENGINEERED Metal Structures
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Carports, Enclosed Carports, Garages, Barns, RV Covers, Boat Covers, Utility Carports, and more...
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Crushed Bend
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        14 Gauge, 2 ½ x 2 ½ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        12 Gauge, 2 ¼ x 2 ¼ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Low Cost with Quality Workmanship
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        3 Ft Ground Anchors Standard, no extra!
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Meets Ground Snow and Wind Loads
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Delivery Included
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Engineering Plans Available Ask A Sales Person
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Galvanized steel tubing resistant to rust, fire, termites and rot.
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        20 Year Limited Warranty Ask A Sales Person
                                    </li>
                                </ul>

                                <p className="align-center font-12 padding-0-5">
                                    <b><span className="danger-color underline">Get instant pricing</span> online for the size structure you are looking for...</b>
                                </p>

                                <div className="align-center">
                                    <button onClick={ () => this.openOnlineBuildModal() }>Build it online!</button>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div>
                               <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />

                                <ul>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        CERTIFIED & ENGINEERED Metal Structures
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Carports, Enclosed Carports, Garages, Barns, RV Covers, Boat Covers, Utility Carports, and more...
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Crushed Bend
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        14 Gauge, 2 ½ x 2 ½ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        12 Gauge, 2 ¼ x 2 ¼ Square Tubing
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Low Cost with Quality Workmanship
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        3 Ft Ground Anchors Standard, no extra!
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Meets Ground Snow and Wind Loads
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Installation Included (on your level land)
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Free Delivery Included
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Engineering Plans Available Ask A Sales Person
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        Galvanized steel tubing resistant to rust, fire, termites and rot.
                                    </li>
                                    <li>
                                        <i className="material-icons success-color">check</i>
                                        20 Year Limited Warranty Ask A Sales Person
                                    </li>
                                </ul>

                                <p className="align-center font-12 padding-0-5">
                                    <b><span className="danger-color underline">Get instant pricing</span> online for the size structure you are looking for...</b>
                                </p>

                                <div className="align-center">
                                    <button onClick={ () => this.openOnlineBuildModal() }>Build it online!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <h5 className="color-red"><i>Why Carports.com?</i></h5>

                        <p>We cover more than 150,000,000 square feet (every year) with metal carports, garages, horse barns and other outdoor covers.  We have any option you're looking for, along with the name and brand you can trust.  When you order a product that will last for years, you want a company that will be there for you, support the product you buy and stand behind it.  You can count on Carports.com and our friendly staff to provide A+ service.</p>

                        <ul>
                            <li>Meet our happy customers!</li>
                            <li>Meet & contact our friendly staff.</li>
                            <li>View videos of our structures online.</li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Sizes Available:</i></h5>

                        <p className="mb-0"><b>Carports & Covers:</b> 7' to 40' Wide</p>
                        <p className="pl-50">Up to 12' Tall, Any Length!</p>

                        <p className="mb-0"><b>Garages & Buildings:</b> 12' to 40' Wide</p>
                        <p className="pl-50">Up to 16' Tall, Any Length!</p>

                        <p><b>Got A Custom Project?</b> We work with thousands of customers, each project is different.  Leave it to our experts to assist you in finding what will fit your needs.  Call us, we specialize in custom built outdoor covers.</p>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Kits or Installation?</i></h5>

                        <p><b>You pick!</b> We carry both options for you, depending on what your need is, which brand your shopping for, etc.</p>
                    </div>

                    <div className="brands">
                        <h5 className="color-red"><i>Featured Brands...</i></h5>

                      <div className="align-center">
                            <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                            <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                             <ReactImageFallback src={ westLogo } fallbackImage={westLogofb} initialImage="loader.gif" alt="West Coast carports logo" />
                      
                        </div>

                        <div className="align-center">
                            <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />
                            <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Coast to Coast logo" />
                            <ReactImageFallback src={ interLogo } fallbackImage={interLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                            
                        </div>
                    </div>	

                </div>

                <OnlineBuild />

            </main>
        );
    }
}

export default Comparison;
