import React from 'react';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';

const sortedGalleryData = [];
 
class AboutUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main  >

                <div id="aboutTop">
                <div className="container mt-30 mb-50">
                    
                    <div className= "first">

                    <div className="vertical-center">
                    <Link to="/extra_wide">
                        <button type="button" class="btn btn-secondary btn-lg">Browse Inventory</button>
                        </Link>
                    </div>
                    
                    
                    </div>

                    

                    <div className= "second"> <div className="vertical-center">
                    <Link to="/extra_wide">
                        <button type="button" class="btn btn-secondary btn-lg">Get Pricing</button>
                        </Link>
                    </div>
                    
                    </div>
                    

                    <div className= "third"> <div className="vertical-center">
                    <Link to="/extra_wide">
                        <button type="button" class="btn btn-secondary btn-lg">Check Styles</button>
                        </Link>
                    </div>
                    </div>

                   
                </div>
                </div>


                <div className="tntabout align-center">
                    <div className="container">

                        
                        <p><b>
                        Carports.com was created to simplify shopping for your choice of metal carports, garages, storage buildings, rv covers, boat covers, barns, other steel structures and many other related products.
                        </b></p>

                        <p><b>Our family of related websites include many nationally well known and visited destinations that provide a wealth of information, pictures and user-friendly online builders so that customers may get estimates 24 hours a day, 7 days a week.  We are dedicated to providing you with the best products and service in the metal structure industry.</b></p>

                        <p><b>Carports.com and it's network of online stores are owned and operated by T-N-T Carports, Inc.  T-N-T Carports, Inc. is headquartered and main manufacturing facility is located in Mount Airy, North Carolina.  We have grown from a small family business to a full staff of over a 100 employees.</b></p>

                        <p><b>
                        T-N-T Carports, Inc. has grown to be one of the largest carport and building companies and has representatives in 48 states. 

T-N-T Carports, Inc. has expanded its manufacturing plants and are still growing.  Our network of shops and manufacturing facitilities helps you save big!
                        </b></p>
                    
                    </div>
                </div>
                
            </main>

            
        );
    }
}

export default AboutUs;
