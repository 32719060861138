import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu,
  DropdownItem, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../assets/style/custom.css';
import ReactImageFallback from "react-image-fallback";


import logo from '../../assets/images/logo.webp';
import logo2 from '../../assets/imagesSlow/logo.png';
import OnlineBuild from './OnlineBuild';

export default class Hearder extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.toggle1 = this.toggle1.bind(this);
    this.onMouseEnter1 = this.onMouseEnter1.bind(this);
    this.onMouseLeave1 = this.onMouseLeave1.bind(this);

    this.toggle2 = this.toggle2.bind(this);
    this.onMouseEnter2 = this.onMouseEnter2.bind(this);
    this.onMouseLeave2 = this.onMouseLeave2.bind(this);

    this.toggle3 = this.toggle3.bind(this);
    this.onMouseEnter3 = this.onMouseEnter3.bind(this);
    this.onMouseLeave3 = this.onMouseLeave3.bind(this); 
   

    this.state = {
      isOpen: false,
      dropdownOpen1: false,
      dropdownOpen2: false,
      dropdownOpen3: false,
      cartModal : false,
      loginModal : false,
      state: "North Carolina",
      state4Render: true,
    };
  }

  addDefaultSrc(ev){
    ev.target.src = '../../assets/imagesSlow/logo.png'
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }

  toggle2() {
    this.setState(prevState => ({
      dropdownOpen2: !prevState.dropdownOpen2
    }));
  }

  toggle3() {
    this.setState(prevState => ({
      dropdownOpen3: !prevState.dropdownOpen3
    }));
  }

  onMouseEnter1() {
    this.setState({dropdownOpen1: true});
  }

  onMouseLeave1() {
    this.setState({dropdownOpen1: false});
  }

  onMouseEnter2() {
    this.setState({dropdownOpen2: true});
  }

  onMouseLeave2() {
    this.setState({dropdownOpen2: false});
  }

  onMouseEnter3() {
    this.setState({dropdownOpen3: true});
  }

  onMouseLeave3() {
    this.setState({dropdownOpen3: false});
  }

  cart_modal_toggle() {
    this.setState(prevState => ({
      cartModal: !prevState.cartModal
    }));
  }

  openOnlineBuildModal() {
    window.onlineBuildModal = true;
    this.setState({ state4Render: true });
  }

  render() {
    let state = "";
    if( window.state )
      state = window.state;
    else 
      state = this.state.state;

    return (
      <div className="bg-black" >
        <NavbarBrand href="/">
          <a href= "/"> 
            <ReactImageFallback src={ logo } fallbackImage={ logo2 } alt="Logo for carports.com" initialImage="loader.gif" />
          </a>
        </NavbarBrand>

        <Navbar light expand="md" className="container" id="header">
          <NavbarToggler onClick={this.toggle} aria-label="Navigation-Menu" />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="">
              <a href= "/"> Home</a>
              </NavItem>

              <NavItem >
                <a onClick={ () => this.openOnlineBuildModal() }>
                  Build & Price 3D
                </a>
              </NavItem>

              <Dropdown nav inNavbar
                onMouseOver={this.onMouseEnter2} 
                onMouseLeave={this.onMouseLeave2} 
                isOpen={this.state.dropdownOpen2} 
                toggle={this.toggle2} >

                <a href="/steel_buildings">
                  Structures
                </a>
                <DropdownMenu>
                  <a href= "/steel_buildings"> Steel Buildings</a>
                  <a href= "/Lean_tos"> Lean-Tos</a>
                  <a href= "/rv_covers"> R.V Covers</a>
                  <a href= "/barns"> Barns</a>
                  <a href="/run_in_sheds">Run In Shed</a>
                  <a href="/extra_wide">Extra Wide</a>
                  <a href="/customize">Custom Designs</a>
                </DropdownMenu>
              </Dropdown>

              <NavItem className="">
              <a href="/carports">Carports</a> 
              </NavItem>

              <NavItem>
              <a href="/agricultural">Agricultural</a>
              </NavItem>

              <NavItem className="login cursor-pointer">
                <NavLink onClick={ e => this.setState({ loginModal: !this.state.loginModal }) }>Log in</NavLink>
              </NavItem>
              
              <NavItem className="cart cursor-pointer">
                <NavLink onClick={ e => this.setState({ cartModal: !this.state.cartModal }) }>Cart</NavLink>
              </NavItem>

             	<NavItem className="search">
                <NavLink href="">
                    <i className="material-icons">search</i>
                </NavLink>
              </NavItem>
              
            </Nav>
          </Collapse>
        </Navbar>

        {/* Cart Modal */}
        <Modal isOpen={ this.state.cartModal } toggle={ () => this.cart_modal_toggle() } >
            <ModalBody className="modal-body">
                <div className="agree-alert">Coming Soon</div>                
            </ModalBody>
        </Modal> 

        {/* Login Modal */}
        <Modal isOpen={ this.state.loginModal } toggle={ () => this.setState({ loginModal: !this.state.loginModal }) } >
            <ModalBody className="modal-body">
              <form action="https://carports.net/pricing_system/add_entry_new2.php" method="GET">
                <div className="row">
                  <div className="col-md-12 align-center">
                    <h6 className="mb-20"><b>Log In</b></h6>
                  </div>

                  <div className="col-md-12">
                    <input type="text" id="email" name="email" placeholder="Email" />
                  </div>

                  <input type="hidden" name="state" value={ state } />

                  <div className="col-md-12">
                    <input type="submit" className="submit-btn mb-20" value="Login" />
                  </div>
                </div>
              </form>        
            </ModalBody>
        </Modal> 

        <OnlineBuild />
      </div>
    );
  }
}

