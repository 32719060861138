import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

import tntlogo from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogofb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import tntMetalLogo from '../../assets/images/Disclaimer/tnt-metal-buildings.webp';
import tntMetalLogofb from '../../assets/imagesSlow/Disclaimer/tnt-metal-buildings.bmp';
import triLogo from '../../assets/images/Disclaimer/tristate.webp';
import triLogofb from '../../assets/imagesSlow/Disclaimer/tristate.bmp';
import westLogo from '../../assets/images/Disclaimer/westcoast.webp';
import westLogofb from '../../assets/imagesSlow/Disclaimer/westcoast.bmp';
import amerLogo from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amerLogofb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import coastLogo from '../../assets/images/Disclaimer/coast-to-coast.webp';
import coastLogofb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import interLogo from '../../assets/images/Disclaimer/interstate.webp';
import interLogofb from '../../assets/imagesSlow/Disclaimer/interstate.bmp';

import tntlogo2 from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogo2fb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import red from '../../assets/images/Color-Charts/tnt barn-red.webp';
import redfb from '../../assets/imagesSlow/Color-Charts/tnt barn-red.jpg';
import brown from '../../assets/images/Color-Charts/tnt brown.webp';
import brownfb from '../../assets/imagesSlow/Color-Charts/tnt brown.jpg';
import burg from '../../assets/images/Color-Charts/tnt burgundy.webp';
import burgfb from '../../assets/imagesSlow/Color-Charts/tnt burgundy.jpg';
import raw from '../../assets/images/Color-Charts/tnt rawhide.webp';
import rawfb from '../../assets/imagesSlow/Color-Charts/tnt rawhide.jpg';

import amslogo2 from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amslogo2fb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import black from '../../assets/images/Color-Charts/tnt black.webp';
import blackfb from '../../assets/imagesSlow/Color-Charts/tnt black.jpg';
import white from '../../assets/images/Color-Charts/tnt white.webp';
import whitefb from '../../assets/imagesSlow/Color-Charts/tnt white.jpg';
import green from '../../assets/images/Color-Charts/tnt evergreen.webp';
import greenfb from '../../assets/imagesSlow/Color-Charts/tnt evergreen.jpg';
import clay from '../../assets/images/Color-Charts/tnt clay.webp';
import clayfb from '../../assets/imagesSlow/Color-Charts/tnt clay.jpg';

import c2clogo2 from '../../assets/images/Disclaimer/coast-to-coast.webp';
import c2clogo2fb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import pewter from '../../assets/images/Color-Charts/tnt pewter-gray.webp';
import pewterfb from '../../assets/imagesSlow/Color-Charts/tnt pewter-gray.jpg';
import pebble from '../../assets/images/Color-Charts/tnt pebble-beige.webp';
import pebblefb from '../../assets/imagesSlow/Color-Charts/tnt pebble-beige.jpg';
import quaker from '../../assets/images/Color-Charts/tnt quaker-gray.webp';
import quakerfb from '../../assets/imagesSlow/Color-Charts/tnt quaker-gray.jpg';
import slate from '../../assets/images/Color-Charts/tnt slate-blue.webp';
import slatefb from '../../assets/imagesSlow/Color-Charts/tnt slate-blue.jpg';




import states from '../../assets/data/states.json';
import FinanceAndCoupon from '../common/FinanceAndCoupon.js';

class ColorCharts extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state : "North Carolina",
            buildModal: false,
            buildFName: "",
            buildLName: "",
            buildPhone: "",
            buildEmail: "",
            buildState: "",
            isAgreeBuild: true,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    change_state(event) {
        if( event.target.value != "" ) {
            this.setState({ buildState : event.target.value });
            this.setState({ buildModal : !this.state.buildModal });
        }
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="color_charts" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>Color Charts</h2>

                    <p>We have different color charts for you to choose from depending on which brand you are shopping for:</p>

                    <div className="mt-30">
                        <h5 className="danger-color mb-20">Colors for the brands: 
                            <span className="black-color font-12"> (Sheet metal colors are the same for these brands)</span>
                        </h5>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="align-center brand">
                                     <ReactImageFallback src={ tntlogo2 } fallbackImage={tntlogo2fb} initialImage="loader.gif" alt="Tnt logo" />
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ red } fallbackImage= { redfb } initialImage="loader.gif" alt="Red Color" />
                                    <span> - Barn Red</span>
                                </div>

                                <div className="item">
                                   <ReactImageFallback src={ brown } fallbackImage={brownfb} initialImage="loader.gif" alt="Brown Color" />
                                    <span> - Brown</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ burg } fallbackImage={burgfb} initialImage="loader.gif" alt="Burgandy Color" />
                                    <span> - Burgundy</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ raw } fallbackImage={ rawfb } initialImage="loader.gif" alt="Rawhide Tan Color" />
                                    <span> - Rawhide</span>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="align-center brand">
                                     <ReactImageFallback src={ amslogo2 } fallbackImage={ amslogo2fb } initialImage="loader.gif" alt="Tnt logo" />
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ black } fallbackImage={ blackfb } initialImage="loader.gif" alt="Black Color" />
                                    <span> - Black</span>
                                </div>

                                <div className="item">
                                   <ReactImageFallback src={ white } fallbackImage={whitefb} initialImage="loader.gif" alt="White Color" />
                                    <span> - White</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ green } fallbackImage={greenfb} initialImage="loader.gif" alt="Green Color" />
                                    <span> - Evergreen</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ clay } fallbackImage={ clayfb } initialImage="loader.gif" alt="Clay Color" />
                                    <span> - Clay</span>
                                </div>
                            </div>
                            
                            <div className="col-md-4">
                                <div className="align-center brand">
                                    <ReactImageFallback src={ c2clogo2 } fallbackImage={c2clogo2fb} initialImage="loader.gif" alt="Tnt logo" />
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ pewter } fallbackImage={pewterfb} initialImage="loader.gif" alt="Pewter-Gray Color" />
                                    <span> - Barn Red</span>
                                </div>

                                <div className="item">
                                   <ReactImageFallback src={ pebble } fallbackImage={pebblefb} initialImage="loader.gif" alt="Pebble-Beige Color" />
                                    <span> - Brown</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ quaker } fallbackImage={quakerfb} initialImage="loader.gif" alt="Quaker-Gray Color" />
                                    <span> - Burgundy</span>
                                </div>

                                <div className="item">
                                    <ReactImageFallback src={ slate } fallbackImage={ slatefb } initialImage="loader.gif" alt="Slate-Blue Color" />
                                    <span> - Rawhide</span>
                                </div>
                                </div>
                        </div>

                        <div className="request mt-50 mb-50">
                            <h5 className="align-center">Request metal samples to be mailed to you, <span className="danger-color underline">click here</span>!</h5>

                            <p>With Carports.com, you get the best service, quality and products...  We're the largest metal carport & garage retailer in the USA!  If you have a question, or unsure on which route would work best for your product... call us and we'll help you figure out what fits you best!</p>

                            <FinanceAndCoupon />
                        </div>
                    </div>

                    <hr />

                    <div>
                        <h5 className="color-red"><i>Why Carports.com?</i></h5>

                        <p>We cover more than 150,000,000 square feet (every year) with metal carports, garages, horse barns and other outdoor covers.  We have any option you're looking for, along with the name and brand you can trust.  When you order a product that will last for years, you want a company that will be there for you, support the product you buy and stand behind it.  You can count on Carports.com and our friendly staff to provide A+ service.</p>

                        <ul>
                            <li>Meet our happy customers!</li>
                            <li>Meet & contact our friendly staff.</li>
                            <li>View videos of our structures online.</li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Sizes Available:</i></h5>

                        <p className="mb-0"><b>Carports & Covers:</b> 7' to 40' Wide</p>
                        <p className="pl-50">Up to 12' Tall, Any Length!</p>

                        <p className="mb-0"><b>Garages & Buildings:</b> 12' to 40' Wide</p>
                        <p className="pl-50">Up to 16' Tall, Any Length!</p>

                        <p><b>Got A Custom Project?</b> We work with thousands of customers, each project is different.  Leave it to our experts to assist you in finding what will fit your needs.  Call us, we specialize in custom built outdoor covers.</p>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Kits or Installation?</i></h5>

                        <p><b>You pick!</b> We carry both options for you, depending on what your need is, which brand your shopping for, etc.</p>
                    </div>

                    <div className="brands">
                        <h5 className="color-red"><i>Featured Brands...</i></h5>

                       
                      <div className="align-center">
                            <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                            <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                             <ReactImageFallback src={ westLogo } fallbackImage={westLogofb} initialImage="loader.gif" alt="West Coast carports logo" />
                      
                        </div>

                        <div className="align-center">
                            <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />
                            <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Coast to Coast logo" />
                            <ReactImageFallback src={ interLogo } fallbackImage={interLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                        </div>
                    </div>	

                </div>

                <Modal isOpen={ this.state.buildModal } toggle={ () => this.build_modal_toggle() } >
                    <ModalBody className="modal-body">
                        <form onSubmit={ this.handleBuildFormSubmit } >
                            <div className="row">
                                <div className="col-md-12 align-center">
                                    <h6 className="mb-20"><b>Register Now To Get Pricing</b></h6>
                                </div>
                                
                                <div className="col-md-6 pr-5">
                                    <input type="text" id="fname" name="firstname" placeholder="First Name"
                                        value={this.state.buildFName} onChange={ e => this.setState({ buildFName: e.target.value }) } />
                                </div>

                                <div className="col-md-6 pl-5">
                                    <input type=" text" id="lname" name="lastname" placeholder="Last Name"
                                        value={this.state.buildLName} onChange={ e => this.setState({ buildLName: e.target.value }) } />
                                </div>
                            
                                <div className="col-md-12">
                                    <input type="text" id="phone" name="phone" placeholder="Phone Number"
                                        value={this.state.buildPhone} onChange={ e => this.setState({ buildPhone: e.target.value }) } />
                                </div>

                                <div className="col-md-12">
                                    <input type="text" id="email" name="email" placeholder="Email" 
                                        value={this.state.buildEmail} onChange={ e => this.setState({ buildEmail: e.target.value }) } />
                                </div>

                                <div className="col-md-12">
                                    <select className="pl-10" name="state" onChange={ e => this.setState({ buildState: e.target.value }) }>
                                    {
                                        states.map((item, i) => {
                                            return (
                                                <option key={`key-${i}`} value={ item[0] } defaultValue={ item[0] == state ? "selected" : "" } >
                                                    { item[0] }
                                                </option>
                                            )
                                        })
                                    }
                                    </select>
                                </div>

                                <div className="col-md-12">
                                    <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" ref="build_terms_checkbox" defaultChecked />
                                    <label htmlFor="styled-checkbox-2">Agree to <a href="">Terms and Conditions</a></label>
                                </div>

                                <div className="col-md-12">
                                    <input type="submit" className="submit-btn mb-20" value="START QUOTING NOW" />
                                </div>

                            </div>
                        </form >
                    </ModalBody>
                </Modal>

                <Modal isOpen={ !this.state.isAgreeBuild } toggle={ () => this.is_agree_build_toggle() } >
                    <ModalBody className="modal-body">
                        <div className="agree-alert">You must agree to our terms to use our customized quoting tool.</div>                
                    </ModalBody>
                </Modal>
            </main>
        );
    }
}

export default ColorCharts;
