import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import {geolocated} from 'react-geolocated';
import { withAlert } from 'react-alert';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from 'react-bootstrap/Spinner';

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';

import StateSelection from '../common/StateSelection.js';
import SpecialOffer from '../common/SpecialOffer';
import FinanceAndCoupon from '../common/FinanceAndCoupon';

const sortedGalleryData = [];
 
class RuninSheds extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isCity: false,
            offerMoreCount : 1,
            offerMoreBtnVisible: true,
            fname: '',
            lname: '',
            phone: '',
            email: '',
            company: '',
            address: '',
            state: 'North Carolina',
            mailSent: false,
            error: null,
            modal: false,
            setModal: false,
            selectedId: '',
            sortedGalleryData: [],
            countMore: 1,
            selectedAction: '',
            weeklySpecials: false,
            weeklyFName: "",
            weeklyLName: "",
            weeklyPhone: "",
            weeklyEmail: "",
            weeklyState: "",
            loading : false,
        };

        this.handleWeeklyFormSubmit = this.handleWeeklyFormSubmit.bind(this);
    }

    componentDidUpdate(preProps) {
        if( preProps.coords !== this.props.coords ) {
            if( this.props.coords != null ) {
                this.getAddress();
            }
        }

        // Loading Spinner Disable
        if( this.state.loading ) {
            this.setState({ loading : false });
        }
            
    }

    getAddress() {
        const url =  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + 
            this.props.coords.latitude +  "," + this.props.coords.longitude + 
            "&key=AIzaSyDnMwc_jAKq47oIn8tHfUbha7HMk6ByVVs";
        console.log(url);    

        const us_states = [ ["1", "Alabama"], ["1", "Alaska"], ["2", "Arizona"], ["2", "Arkansas"], ["3", "California"]
            , ["3", "Colorado"], ["4", "Connecticut"], ["4", "Delaware"], ["5", "Florida"], ["5", "Georgia"]
            , ["6", "Hawaii"], ["6", "Idaho"], ["7", "Illinois"], ["7", "Indiana"], ["8", "Iowa"]
            , ["8", "Kansas"], ["9", "Kentucky"], ["9", "Louisiana"], ["10", "Maine"], ["10", "Maryland"]
            , ["11", "Massachusetts"], ["11", "Michigan"], ["11", "Minnesota"], ["12", "Mississippi"], ["12", "Missouri"]
            , ["12", "Montana"], ["13", "Nebraska"], ["13", "Nevada"], ["13", "New Hampshire"], ["14", "New Jersey"]
            , ["14", "New Mexico"], ["14", "New York"], ["15", "North Carolina"], ["15", "North Dakota"], ["15", "Ohio"]
            , ["16", "Oklahoma"], ["16", "Oregon"], ["16", "Pennsylvania"], ["17", "Rhode Island"], ["17", "South Carolina"]
            , ["17", "South Dakota"], ["18", "Tennessee"], ["18", "Texas"], ["18", "Utah"], ["19", "Vermont"]
            , ["19", "Virginia"], ["19", "Washington"], ["20", "West Virginia"], ["20", "Wisconsin"], ["20", "Wyoming"]
        ];    

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if( data["results"] != "" ) {
                    data["results"].map( (data_result, j) => {
                        us_states.map( (us_state, i) => {
                            if( data_result["formatted_address"].indexOf( us_state[1] ) != -1 ) {
                                this.setState({ state : us_state[1] });
                            }
                        });
                    } )
                    
                }
        });
    }

    responsive = {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
    };

    sliderItem = (item, i) => (
        <div key={`key-${i}`} className="slider-item">
            <img src={ item["image"] } alt="Slider" />
            <div className="price">Image - { item["number"] } { item["price"][this.state.state] }</div>
        </div>
    )

    more() {
        this.setState({ countMore: this.state.countMore + 1 });

        // Loading Spinner Enable
        this.setState({ loading : true });
    }

    handleFormSubmit( event ) {
        event.preventDefault();
        
        // Getting The Product Info From Json Data
        const selectedId = this.state.selectedId;
        let title = "";
        let description = "";
        let price = "";
        let category = "";
        let number = "";
        
        sortedGalleryData.map((item, i) => {
            if( i == selectedId ) {
                title = item["title"];
                description = item["description"];
                price = item["price"][this.state.state];
                category = item["category"];
                number = item["number"];
            }
        })

        console.log("=======", title);

        // Adding 'https://cors-anywhere.herokuapp.com/' to the URL for not getting the CROS Origin Error
        const API_PATH = 'https://cors-anywhere.herokuapp.com/http://tubemetalshop.com/Dev/tube_metal_shop/api/index.php';

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: { 
                fname   : this.state.fname,
                lname   : this.state.lname,
                phone   : this.state.phone,
                email   : this.state.email,
                state   : this.state.state,
                title   : title,
                price   : price,
                category: category,
                number  : number,
                description    : description,
            }
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent
            })

            if( result.data.sent == true ) {
                if( this.state.selectedAction == "Buy Now" )
                    alert("Thank you, someone will call you shortly. Or feel free to call 1-888-822-7767 and talk to a rep now.");
                else 
                    alert("Your info has been sent successfully.");
                
                this.toggle();
            } else {
                alert("You should fill out each fields.");
            }
        })
        .catch(error => {
            this.setState({ error: error.message });

            alert( error.message );
        });
    }

    toggle() {
        this.setState({ modal : !this.state.modal });
    }

    open_modal(id, selectedAction) {
        this.setState({ modal : !this.state.modal });
        this.setState({ selectedId : id });
        this.setState({ selectedAction : selectedAction });
    }

    weekly_toggle() {
        this.setState({ weeklySpecials : !this.state.weeklySpecials });
    }

    handleWeeklyFormSubmit( event ) {
        event.preventDefault();

        if( !this.refs.terms_checkbox.checked )
            this.setState({ isAgree : false });
        else {
            let state = "";
            if( this.state.registerState ) {
                state = this.state.registerState;
            } else {
                if( window.state )
                    state = window.state;
                else 
                    state = this.state.state;
            }

            const params = 'firstname=' + this.state.firstname + '&lastname=' + this.state.lastname + 
                '&email=' + this.state.email + '&phone=' + this.state.phone + '&state=' + state;
            // window.open('http://carports.net/pricing_system/add_entry2.php?' + params, '_self');
        }
    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }
    
    render() {
        // Sorting The Gallery Data
        sortedGalleryData.length = 0;
        productCategories.map((item) => {
            Object.keys(galleryData).map((key) => {
                if( item == "run-in-sheds" && galleryData[key]['category'] == "run-in-sheds" ) {
                    sortedGalleryData.push( galleryData[key] );
                }
            })
        });

        productCategories.map((item) => {
            Object.keys(galleryData).map((key) => {
                if( item != "run-in-sheds" && galleryData[key]['category'] == item ) {
                    sortedGalleryData.push( galleryData[key] );
                }
            })
        });

        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        const sliderItemsHtml = sortedGalleryData.slice(0,75).map(this.sliderItem);
        
        const { loading } = this.state;

        return (
            <main id="carports" className="carports">
                <div className="loading-spinner">
                    <button className="button" onClick={ () => this.setState({ loading : true }) } disabled={ loading }>
                        { loading && <i className="fa fa-refresh fa-spin"></i> }
                    </button>
                </div>
                
                <div className="slider-section">
                    <div className="container">
                        <div className="garages-gallery">
                            <button onClick={() => this.Carousel._slidePrev()} className="left-arrow">
                                <img src={ left_arrow } alt="Left Arrow" />
                            </button>
                            
                            <button onClick={() => this.Carousel._slideNext()} className="right-arrow">
                                <img src={ right_arrow } alt="Right Arrow" />
                            </button>
                            
                            <AliceCarousel
                                items={sliderItemsHtml}
                                dotsDisabled={true}
                                buttonsDisabled={true}
                                responsive={this.responsive}
                                ref={ el => this.Carousel = el }
                            />
                        </div>
                    </div>
                </div>
                
                <div className="product-section container">
                    <div className="row">
                    {
                        sortedGalleryData.map((item, i) => {
                            if( i < 10 * this.state.countMore ) {
                                const image = item['image'];
                                return (
                                    <div key={ `${i}` } className="col-md-6">
                                        <div className="item">
                                            <img src={ `${image}` } alt={ item['category'] } />
                                            <p className="align-center mt-10">Image - { item['number'] }</p>

                                            <div className="title-div">
                                                <span className="title float-left">{ item['title'] }</span>
                                                <span className="price float-right">{ item['price'][this.state.state] }</span>
                                            </div>

                                            <div className="description">
                                                { item['description'] }
                                            </div>

                                            <div className="button-div align-right">
                                                {/* <button onClick={ () => this.open_modal(i, "Buy Now") }>PRICE NOW</button>
                                                <button onClick={ () => this.open_modal(i, "Email Quote") }>EMAIL QUOTE</button> */}
                                                <button onClick={ () => this.openOnlineBuildModal()}>PRICE NOW</button>
                                                <button onClick={ () => this.openOnlineBuildModal() }>EMAIL QUOTE</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            
                        })
                    }
                    </div>

                    <div className={ this.state.countMore * 10 < sortedGalleryData.length ? "more-btn-div mt-0 mb-50" : "more-btn-div hidden" }>
                        <button className="more-btn" onClick={() => this.more()}>
                            <i className="material-icons">more_horiz</i>More
                        </button>
                    </div>

                </div>

                <StateSelection />

                {/* <SpecialOffer /> */}

                <FinanceAndCoupon />

            </main>
        );
    }
}

const geoWrapper = geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(RuninSheds);

const alertWrapper = withAlert(geoWrapper)

export default alertWrapper;
