import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';
import dm3 from '../../assets/images/Disclaimer/carport-damage-3-small.webp';
import dm3fb from '../../assets/imagesSlow/Disclaimer/carport-damage-3-small.jpg';
import dm4 from '../../assets/images/Disclaimer/carport-damage-4-small.webp';
import dm4fb from '../../assets/imagesSlow/Disclaimer/carport-damage-4-small.jpg';
import dm5 from '../../assets/images/Disclaimer/carport-damage-5-small.webp';
import dm5fb from '../../assets/imagesSlow/Disclaimer/carport-damage-5-small.jpg';
import dm6 from '../../assets/images/Disclaimer/carport-damage-6-small.webp';
import dm6fb from '../../assets/imagesSlow/Disclaimer/carport-damage-6-small.jpg';
import dm7 from '../../assets/images/Disclaimer/carport-damage-7-small.webp';
import dm7fb from '../../assets/imagesSlow/Disclaimer/carport-damage-7-small.jpg';
import dm1 from '../../assets/images/Disclaimer/carport-damage-1-small.webp';
import dm1fb from '../../assets/imagesSlow/Disclaimer/carport-damage-1-small.jpg';
import dm2 from '../../assets/images/Disclaimer/carport-damage-2-small.webp';
import dm2fb from '../../assets/imagesSlow/Disclaimer/carport-damage-2-small.jpg';
import tntlogo from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogofb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import tntMetalLogo from '../../assets/images/Disclaimer/tnt-metal-buildings.webp';
import tntMetalLogofb from '../../assets/imagesSlow/Disclaimer/tnt-metal-buildings.bmp';
import triLogo from '../../assets/images/Disclaimer/tristate.webp';
import triLogofb from '../../assets/imagesSlow/Disclaimer/tristate.bmp';
import westLogo from '../../assets/images/Disclaimer/westcoast.webp';
import westLogofb from '../../assets/imagesSlow/Disclaimer/westcoast.bmp';
import amerLogo from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amerLogofb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import coastLogo from '../../assets/images/Disclaimer/coast-to-coast.webp';
import coastLogofb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import interLogo from '../../assets/images/Disclaimer/interstate.webp';
import interLogofb from '../../assets/imagesSlow/Disclaimer/interstate.bmp';


                       

const sortedGalleryData = [];
 
class Disclaimer extends React.Component {
   

    constructor(props) {
        super(props);
        this.state = { 
            weeklySpecials: false,
            weeklyFName: "",
            weeklyLName: "",
            weeklyPhone: "",
            weeklyEmail: "",
            weeklyState: "",
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    responsive = {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
    };

    sliderItems = [ 
        [slider_1, 'Wedding Barn', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Boat Dock Cover', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Display Lot', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [slider_1, 'Cafe Table Cover', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'],
        [slider_1, 'Wedding Barn', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'] 
    ];

    render() {
        // Sorting The Gallery Data
        // sortedGalleryData.length = 0;
        // productCategories.map((item) => {
        //     Object.keys(galleryData).map((key) => {
        //         if( item == "carports" && galleryData[key]['category'] == "carports" ) {
        //             sortedGalleryData.push( galleryData[key] );
        //         } else if( item != "carports" && galleryData[key]['category'] == item ) {
        //             sortedGalleryData.push( galleryData[key] );
        //         }
        //     })
        // })

        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        // const stateImg = '/images/states/' + state + '.webp';

        // const sliderItemsHtml = sortedGalleryData.map(this.sliderItem);

        return (
            <main id="Disclaimer" className="docs">
         
                <div className="container mt-30 mb-50">
              
                    <h2>Damaged Carport</h2>
                    <div>
                 
                        <p>This is a good example of why you should check with our sales staff on what type of roofing would be good for your structure.</p>

                        <p>If you live in an area that has heavy snow/rain/etc. we have a type of roof that will be able to endure your weather conditions!  You may call us toll free 888-822-7767 or look up your local number online.</p>

                        <p className="disclaimer-text">This customer ordered from another company, then after collapsing, contacted Carports.com for expert advise on the correct type of structure she needed for her area.  Now a happy customer with a strong engineered structure, even at an affordable price!</p>

                        <div className="damaged-images">
                            <ReactImageFallback src={ dm1 } fallbackImage={ dm1fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm2 } fallbackImage={ dm2fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm3 } fallbackImage={ dm3fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm4 } fallbackImage={ dm4fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm5 } fallbackImage={ dm5fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm6 } fallbackImage={ dm6fb} initialImage="loader.gif"/>
                           <ReactImageFallback src={ dm7 } fallbackImage={ dm7fb} initialImage="loader.gif"/>

                        </div>
                    </div>	

                    <hr />

                    <div>
                        <h5 className="color-red"><i>Why Carports.com?</i></h5>

                        <p>We cover more than 150,000,000 square feet (every year) with metal carports, garages, horse barns and other outdoor covers.  We have any option you're looking for, along with the name and brand you can trust.  When you order a product that will last for years, you want a company that will be there for you, support the product you buy and stand behind it.  You can count on Carports.com and our friendly staff to provide A+ service.</p>

                        <ul>
                            <li>Meet our happy customers!</li>
                            <li>Meet & contact our friendly staff.</li>
                            <li>View videos of our structures online.</li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Sizes Available:</i></h5>

                        <p className="mb-0"><b>Carports & Covers:</b> 7' to 40' Wide</p>
                        <p className="pl-50">Up to 12' Tall, Any Length!</p>

                        <p className="mb-0"><b>Garages & Buildings:</b> 12' to 40' Wide</p>
                        <p className="pl-50">Up to 16' Tall, Any Length!</p>

                        <p><b>Got A Custom Project?</b> We work with thousands of customers, each project is different.  Leave it to our experts to assist you in finding what will fit your needs.  Call us, we specialize in custom built outdoor covers.</p>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Kits or Installation?</i></h5>

                        <p><b>You pick!</b> We carry both options for you, depending on what your need is, which brand your shopping for, etc.</p>
                    </div>

                    <div className="brands">
                        <h5 className="color-red"><i>Featured Brands...</i></h5>

                        <div className="align-center">
                            <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                            <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                             <ReactImageFallback src={ westLogo } fallbackImage={westLogofb} initialImage="loader.gif" alt="West Coast carports logo" />
                      
                        </div>

                        <div className="align-center">
                            <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />
                            <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ interLogo } fallbackImage={interLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                            
                        </div>
                    </div>
                </div>
            </main>
            
        );
    }
}

export default Disclaimer;
