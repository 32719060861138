// IMPORT PACKAGE REFERENCES

import React from 'react';
import MetaTags from 'react-meta-tags';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import {geolocated} from 'react-geolocated';
import { withAlert } from 'react-alert';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';
import { printHelloWorld } from '../../actions/printHelloAction';
import { connect } from 'react-redux';
import ReactImageFallback from "react-image-fallback";

import banner_img_1 from '../../assets/images/mainRenew3.jpg';
import offer_1 from '../../assets/images/barnFront.webp';
import offer_2 from '../../assets/images/carportONdock.webp';
import offer_3 from '../../assets/images/redBarnImage.webp';
import offer_4 from '../../assets/images/end view of existion.webp';
import offer_5 from '../../assets/images/garageWlean.webp';
import offer_1fb from '../../assets/imagesSlow/barnFront.png';
import offer_2fb from '../../assets/imagesSlow/carportdock.png';
import offer_3fb from '../../assets/imagesSlow/redTallBarn.png';
import offer_4fb from '../../assets/imagesSlow/end view of existion.png';
import offer_5fb from '../../assets/imagesSlow/garageWlean.png';
import offer_6fb from '../../assets/imagesSlow/greyShop.png';
import left_arrow from '../../assets/images/left_arrow.webp';
import leftArrowFallBack from '../../assets/imagesSlow/left_arrow.png';
import right_arrow from '../../assets/images/right_arrow.webp';
import rightArrowFallBack from '../../assets/imagesSlow/right_arrow.png';
import brand_1 from '../../assets/imagesSlow/brand_1.png';
import brand_2 from '../../assets/imagesSlow/brand_2.png';
import brand_3 from '../../assets/imagesSlow/brand_3.png';
import brand_4 from '../../assets/imagesSlow/brand_4.png';
import brand_5 from '../../assets/imagesSlow/brand_5.png';
import latest_1 from '../../assets/imagesSlow/Image 17.png';
import latest_2 from '../../assets/imagesSlow/Image 12.png';
import latest_3 from '../../assets/imagesSlow/Image 13.png';
import latest_4 from '../../assets/imagesSlow/Image 14.png';
import latest_5 from '../../assets/imagesSlow/Image 15.png';
import latest_6 from '../../assets/imagesSlow/Image 16.png';
import vimeoVidIcon from '../../assets/imagesSlow/play.gif';
import lean2img from '../../assets/images/lean-tos-1.webp';
import lean2img2 from '../../assets/imagesSlow/lean-tos-1.png';
import lean2img2nd from '../../assets/images/lean-tos-2.webp';
import lean2img2nd2 from '../../assets/imagesSlow/lean-tos-2.png';
import lean23 from '../../assets/images/lean-tos-3.webp'
import lean23fb from '../../assets/imagesSlow/lean-tos-3.png'
import phoneIcon from '../../assets/images/phone_icon_3.webp';
import phoneIcon2 from '../../assets/imagesSlow/phone_icon_3.png';
import sunny from '../../assets/imagesSlow/sunny_icon.png';
import bigred from '../../assets/imagesSlow/steel_buildings.png';
import bigred2 from '../../assets/imagesSlow/steel_buildings.png';
import garageGroup from '../../assets/images/20150825_084026.webp';
import garageGroup2 from '../../assets/imagesSlow/20150825_084026.png';
import rvcover1 from '../../assets/images/rv_cover_1.webp';
import rvcover1fb from '../../assets/imagesSlow/rv_cover_1.png';
import rvcover2 from '../../assets/images/rv_cover_2.webp';
import rvcover2fb from '../../assets/imagesSlow/rv_cover_2.png';
import barn1 from '../../assets/images/barns.webp'
import barn1fb from '../../assets/imagesSlow/barns.png'
import runin1 from '../../assets/images/sheds.webp';
import runin1fb from '../../assets/imagesSlow/sheds.png'
import late1 from '../../assets/images/Image 17.webp';
import late1fb from '../../assets/imagesSlow/Image 17.png';
import late2 from '../../assets/images/Image 12.webp';
import late2fb from '../../assets/imagesSlow/Image 12.png';
import late3 from '../../assets/images/Image 13.webp';
import late3fb from '../../assets/imagesSlow/Image 13.png';
import late4 from '../../assets/images/Image 14.webp';
import late4fb from '../../assets/imagesSlow/Image 14.png';
import late5 from '../../assets/images/Image 15.webp';
import late5fb from '../../assets/imagesSlow/Image 17.png';
import late6 from '../../assets/images/Image 16.webp';
import late6fb from '../../assets/imagesSlow/Image 16.png';
import extrawider from '../../assets/images/wide1.webp';
import extrawiderfb from '../../assets/imagesSlow/wide1.png';
import watchvideo from '../../assets/imagesSlow/watch_video.png';
import watchvideofb from '../../assets/imagesSlow/watch_video.png';
import twoD from '../../assets/images/custom2.webp';
import twoDfb from '../../assets/imagesSlow/custom2.png';
import videosmall from '../../assets/images/video-icon-1.webp';
import videosmall2 from '../../assets/images/video-icon-2.webp';
import fallBackSlider from '../../assets/imagesSlow/main.png';

import videoData from '../../assets/data/videos.json';
import states from '../../assets/data/states.json';
import localNumbers from '../../assets/data/local_number.json';
import customUnitImages from '../../assets/data/custom_unit_images.json';

import PictureLookupTool from './PictureLookupTool';
import SpecialOffer from '../common/SpecialOffer';
import OnlineBuild from '../common/OnlineBuild';
import FinanceAndCoupon from '../common/FinanceAndCoupon';

// Making Random Order Of garagesItems
// 5 is the length of garagesItems
const randomOrder = [];
for(let i = 0; i < 6; i++) {
    randomOrder.push(i);
}
randomOrder.sort((ele1, ele2) => Math.random() - Math.random());

 
class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isCity: false,
            offerMoreCount : 1,
            offerMoreBtnVisible: true,
            latestMoreCount : 1,
            latestMoreBtnVisible: true,
            mouseEnter  : false,
            modal: false,
            onlineBuildModal: false,
            selectedVideo : "",
            state : "Pick Your State",
            isAgree: true,
            firstname: "",
            lastname: "",
            lastname: "",
            phone: "",
            email: "",
            zipcode: "",
            registerState: "",
            weeklySpecials: false,
            weeklyFName: "",
            weeklyLName: "",
            weeklyPhone: "",
            weeklyEmail: "",
            weeklyState: "",
            weeklyZipcode : "",
            chooseVideoModal: false,
            validEmail: true,
        };

    }


/*
    componentDidUpdate(preProps) {
       if( preProps.coords !== this.props.coords ) {
           if( this.props.coords != null ) {
               this.getAddress();
           }
       }
   }*/

   /*
    getAddress() {
        const url =  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + 
            this.props.coords.latitude +  "," + this.props.coords.longitude + 
            "&key=AIzaSyDnMwc_jAKq47oIn8tHfUbha7HMk6ByVVs";
        
        const us_states = [ ["1", "Alabama"], ["1", "Alaska"], ["2", "Arizona"], ["2", "Arkansas"], ["3", "California"]
            , ["3", "Colorado"], ["4", "Connecticut"], ["4", "Delaware"], ["5", "Florida"], ["5", "Georgia"]
            , ["6", "Hawaii"], ["6", "Idaho"], ["7", "Illinois"], ["7", "Indiana"], ["8", "Iowa"]
            , ["8", "Kansas"], ["9", "Kentucky"], ["9", "Louisiana"], ["10", "Maine"], ["10", "Maryland"]
            , ["11", "Massachusetts"], ["11", "Michigan"], ["11", "Minnesota"], ["12", "Mississippi"], ["12", "Missouri"]
            , ["12", "Montana"], ["13", "Nebraska"], ["13", "Nevada"], ["13", "New Hampshire"], ["14", "New Jersey"]
            , ["14", "New Mexico"], ["14", "New York"], ["190", "North Carolina"], ["15", "North Dakota"], ["15", "Ohio"]
            , ["16", "Oklahoma"], ["16", "Oregon"], ["16", "Pennsylvania"], ["17", "Rhode Island"], ["17", "South Carolina"]
            , ["17", "South Dakota"], ["18", "Tennessee"], ["18", "Texas"], ["18", "Utah"], ["19", "Vermont"]
            , ["19", "Virginia"], ["19", "Washington"], ["20", "West Virginia"], ["20", "Wisconsin"], ["20", "Wyoming"]
        ];    

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if( data["results"] != "" ) {
                    data["results"].map( (data_result, j) => {
                        us_states.map( (us_state, i) => {
                            if( data_result["formatted_address"].indexOf( us_state[1] ) != -1 ) {
                                this.props.alert.show(<div> { us_state[1] + " - Group " + us_state[0] } </div>); 
                                this.setState({ state : us_state[1] });
                                window.state = us_state[1];
                            }
                        });

                        console.log("==data_result=====", data_result);

                    } )

                    
                    
                }
        });
    }*/

    responsive_1 = {
        0: { items: 1 },
        600: { items: 3 },
        1024: { items: 4 },
    };

    responsive_2 = {
        0: { items: 1 },
        600: { items: 3 },
        1024: { items: 5 },
    };

    responsive_3 = {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
    };

    responsive_custom_units = {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
    };


    
    garagesItems = [ 
        [offer_1fb, 'Wedding Barn', 'On Sale Now!', 'This wedding barn is  66 w x 100 L  x 16 H with custom windows and doors.'], 
        [offer_2fb, 'Boat Dock Cover', 'On Sale Now!', '14 x 20 x 9 Vertical roof with two gable end caps'], 
        [offer_3fb, 'Display Lot Barn', 'On Sale Now!', 'Center section is 18 w x 30 L  x 16 H and the wings are both 12 x 30 x 13 down to 10.'], 
        [offer_4fb, 'Café  Table Cover', 'On Sale Now!', 'This 30 x 50 x 9 regular style carport cover works perfect out side for dining '],
        [offer_5fb, 'Garage with lean-to', 'On Sale Now!', 'This garage is 24 x 30 x 10 all enclosed with one 12 x 30 x 7 lean-to off the side wall'],
        [offer_6fb, 'Metal Shop', 'On Sale Now!', 'This garage is 18 x 30 x 14 with one 12 x 12 on the front wall'] 
    ];



    bannerImages = [ [banner_img_1]];
    brandItems = [ [brand_1], [brand_2], [brand_3], [brand_4], [brand_5] ];
    latestItems = [ [latest_1], [latest_2], [latest_3], [latest_4], [latest_5], [latest_6] ];
    // customUnitImages = [ [latest_1], [latest_2], [latest_3], [latest_4], [latest_5], [latest_6] ];
  
    galleryItem = (item, i) => (
        <div key={`key-${i}`} className="offer-item">
            <div className="position-relative">
                <ReactImageFallback src={ item[0] } fallbackImage="{ offer_3fb }" initialImage="loader.gif" alt="Gallery Image" className="my-image"  />
                <span className="water-mark">carports.com 888-822-7767</span>
            </div>
            
            <div className="content">
                <h4>{ item[1] }</h4>
                <p>{ item[3] }</p>
                <div className="read-more">
                    <a href="/installation_maual">Brochure</a> 
                    <button aria-label="on Sale">On Sale Now!</button>
                </div>
            </div>
        </div>
    )
    // removed cost from gallery items ${ item[2] } <span>+ tax</span>

    brandItemHtml = (item, i) => (
        <div key={`key-${i}`} className="brand-item">
            <img src={ item[0] } alt="Brand Image" />
        </div>
    )

    bannerSliderHtml = (item, i) => (
        <ReactImageFallback src={ item[0] } fallbackImage={ fallBackSlider } alt="Slider Image" initialImage="loader.gif" />
    )

    customUnitSliderHtml = (item, i) => (
        <img src={ item } alt="Slider Image" />
    )

    offer_more() {
        this.setState({ offerMoreCount: this.state.offerMoreCount + 1 });
        
        if( this.garagesItems.length <= (this.state.offerMoreCount + 1) * 4 ) {
            this.setState({ offerMoreBtnVisible: false });
        }
    }

    latest_more() {
        this.setState({ latestMoreCount: this.state.latestMoreCount + 1 });
        
        if( this.latestItems.length <= (this.state.latestMoreCount + 1) * 4 ) {
            this.setState({ latestMoreBtnVisible: false });
        }
    }

    video_mouse_enter(key) {
        this.setState(prevState => {
            return { mouseEnter: { ...prevState.mouseEnter, [key]: true } };
        });
    }

    video_mouse_leave(key) {
        this.setState(prevState => {
            return { mouseEnter: { ...prevState.mouseEnter, [key]: false } };
        });
    }

    toggle() {
        this.setState({ modal : !this.state.modal });
    }

    toggle_1() {
        this.setState({ isAgree : !this.state.isAgree });
    }

    toggle_2() {
        this.setState({ onlineBuildModal : !this.state.onlineBuildModal });
    }

    weekly_toggle() {
        this.setState({ weeklySpecials : !this.state.weeklySpecials });
    }

    open_video(selectedKey) {
        this.setState({ modal : !this.state.modal });

        Object.keys(videoData).map((key) => {
            if( key == selectedKey ) 
                this.setState({ selectedVideo : videoData[key]["url"] });
        })
        
    }

    change_state(event) {
        if( event.target.value != "" ) {
            this.setState({ state : event.target.value });
            window.state = event.target.value;

        }
    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }

    render() {
        // Making Random Array Of garagesItems
        const randomGaragesItems = [];
        randomOrder.map((item, i) => {
            randomGaragesItems.push( this.garagesItems[item] );
        })

        const garageItemsHtml = randomGaragesItems.map(this.galleryItem);
        const brandsHtml = this.brandItems.map(this.brandItemHtml);
        const bannerSlidersHtml = this.bannerImages.map(this.bannerSliderHtml);
        const customUnitsSliderHtml = customUnitImages.map(this.customUnitSliderHtml);
        
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        const stateImg = '/imagesSlow/states/' + state + '.jpg';

        let videoCount = 0;


        return (
            <main>
                
                <div className="wrapper">
                        <MetaTags>
                        <link rel="font" href="../../assets/style/fontawesome-webfont.20fd1704.woff2"></link>
                            <title>Carports.com - TNT, Metal Carports, Garages, Buildings, RV Covers, Boat Covers, Barns</title>
                            <meta name="description" content="TNT Carports, Garages, Metal Buildings, RV Covers, Boat Covers, Barns, Do It Yourself Kits, Portable Buildings and other engineered tubular frame steel structures by certified TNT." />
                            <meta property="og:title" content="MyApp" />
                            <meta property="og:image" content="path/to/image.webp" />
                        </MetaTags>
                          
                </div>

                <div role="banner" className="banner">
                    <section className="banner-title">
                       <div className="title" id="title2"></div>
                        <div className="welcome">The Largest Online Selection</div>
                        <div className="shopping">
                            <span onClick={ () => this.openOnlineBuildModal() }>BUILD ONLINE NOW</span>
                        </div>

                    </section>

                    <AliceCarousel
                        items={bannerSlidersHtml}
                        dotsDisabled={true}
                        buttonsDisabled={true}
                        autoPlay={false}
                        autoPlayInterval={4000}
                        duration={1000}
                        responsive={this.responsive_3}
                        ref={ el => this.Carousel3 = el }
                    />
                </div>

                <div className="phone-number">
                    <p><span>1-888-822-7767</span></p>
                    <div className="chat"><i className="material-icons">forum</i>Chat with an expert</div>
                </div>

               <PictureLookupTool />

                <div className="offer-section">
                    <div className="section-title">
                        <h4>What We <span>Offer?</span></h4>
                        <p>We are a tubular steel manufacturer who engineers, delivers, and installs our building system.</p>
                    </div>

                    <div className="gallery-container expand-view">
                        <div className="container">
                            <div className="garages-gallery">
                                <button aria-label="Move Images" onClick={() => this.Carousel1._slidePrev()} className="left-arrow" aria-label="Left Arrow">
                                    <ReactImageFallback src={ left_arrow } initialImage="loader.gif" fallbackImage={ leftArrowFallBack } alt="Left Arrow" />
                                </button>
                                
                                <button aria-label="move images" onClick={() => this.Carousel1._slideNext()} className="right-arrow" aria-label="Right Arrow">
                                    <ReactImageFallback src={ right_arrow } initialImage="loader.gif" fallbackImage={ rightArrowFallBack } alt="Right Arrow" />
                                </button>
                                
                                <AliceCarousel
                                    items={garageItemsHtml}
                                    dotsDisabled={true}
                                    buttonsDisabled={true}
                                    responsive={this.responsive_1}
                                    ref={ el => this.Carousel1 = el }
                                    autoPlay={true}
                                    autoPlayInterval={2000}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="alice-carousel mobile-view" aria-label="sale items" >
                        {
                            randomGaragesItems.map((item, i) => {
                                if( i < 6 * this.state.offerMoreCount ) {
                                    return (
                                        <div key={`key-${i}`} className="offer-item">
                                            <div className="position-relative">
                                                <img src={ item[0] } alt="Gallery Image" />
                                                <span className="water-mark" aria-hidden='true'>carports.com 888-822-7767</span>
                                            </div>
                                            <div className="content" alt="gallery image">
                                                <h4>{ item[1] }</h4>
                                                <p>{ item[3] }</p>
                                                <div className="read-more">
                                                 {/* Lean-tos Section  <a href="#">Read More</a>*/ }
                                                    <button aria-label="read more">{ item[2] } <span></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            })
                        }

                        <div className={this.state.offerMoreBtnVisible ? "more-btn-div" : "more-btn-div hidden"}>
                            <button aria-label="more" className="more-btn" onClick={() => this.offer_more()}>
                                <i className="material-icons">more_horiz</i>More
                            </button>
                        </div>
                    </div>
                </div>

                <div className="videos-section container">
                    <div className="section-title">
                        <h4>Our <span>Videos</span></h4>
                        <p>We are a tubular steel manufacturer who engineers, delivers, and installs our building system.</p>
                    </div>

                    <div className="row">
                        {
                            Object.keys(videoData).map((key) => {
                                videoCount++;
                                
                                if( videoCount <= 8 ) {
                                    return(
                                        <div key={`${key}`} className="item col-md-3 col-sm-6">
                                            <div className="video" 
                                                onMouseEnter={ () => this.video_mouse_enter(key) } 
                                                onMouseLeave={ () => this.video_mouse_leave(key) }
                                            >
                                                <a onClick={ () => this.open_video(key) }>
                                                    <img className="video-img" src={ videoData[key]["img"] } alt="Video" />
                                                </a>
                                                <img className={ this.state.mouseEnter[key] ? "video-icon-1" : "hidden" } src={ videosmall } alt="Video" />
                                                <img className={ this.state.mouseEnter[key] ? "video-icon-2" : "hidden" } src={ videosmall2 } alt="Video" />
                                            </div>
                                            
                                            <div className="content">
                                                <h6>{ videoData[key]["title"] }</h6>
                                                <p><img src={vimeoVidIcon}  alt="Icon" />{ videoData[key]["sub_title"] }</p>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            })
                        }
                    </div>

                    <Modal isOpen={ this.state.modal } toggle={ () => this.toggle() } >
                        <ModalBody className="modal-body">
                            <ReactPlayer url={ this.state.selectedVideo } playing loop />
                        </ModalBody>
                    </Modal>
                </div>

                {/* Lean-tos Section */}
                <div id="lean_tos_section" className="container">
                    <div className="imgs">
                        <div className="row">
                            <div className="table-cell col-md-8">
                                <ReactImageFallback src={lean2img} fallbackImage={lean2img2} initialImage="loader.gif" alt="lean tos 1" />
                            </div>

                            <div className="table-cell col-md-4 expand-view">
                                <div className="table">
                                    <div className="table-row">
                                        <div className="" id="lean_tos_2">
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="" id="lean_tos_3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-view mt-15">
                            <div className="row">
                                <div className="col-6">
                                    <ReactImageFallback src={lean2img2nd} fallBackImage={lean2img2nd2} initialImage="loader.gif" alt="lean tos 2" />
                                </div>

                                <div className="col-6">
                                    <ReactImageFallback src={ lean23 } fallbaclImage={lean23fb} alt="lean tos 3" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="desc">
                        <div className="section-title align-center">
                            <a href="/lean_tos">
                                <h2>Lean-tos</h2>
                            </a>
                            <p>Do you have a unique spot that could use our lean-to design? Recycle a preexisting wall and make the most out of your property! </p>
                           <a href="/lean_tos">
                            <button aria-label="more designs" className="btn-style-1">
                                
                                    CLICK HERE TO CHECK OUT MORE DESIGNS
                                    <i className="material-icons" >keyboard_arrow_right</i>
                                
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
                {/* Lean-tos Section End */}

                <div className="services-section container section-space">
                    <div className="section-title">
                        <h4>Our <span>Services</span></h4>
                        <p>With over 25 years in business we’ve setup a nation wide network.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                        	<div className="item">
	                            <h4>Pro Installation</h4>
	                            <p>We will deliver, install and anchor our building system on your land.</p>
	                            <i className="material-icons">build</i>
	                        </div>
                    	</div>
                    	
                        <div className="col-md-4">
  							<div className="item">
	                            <h4>Building Specialist</h4>
	                            <p>We hold contractors licenses in almost all states as well as a business license. Let us help you understand your options.</p>
	                            <i className="material-icons">account_box</i>
                        	</div>
                        </div>

                        <div className="col-md-4">
                        	<div className="item">
	                            <h4>Repairs or upgrades</h4>
	                            <p>Start with a basic design then add onto it as you wish. Upgrades are a huge part of our business!</p>
	                            <i className="material-icons">work_outline</i>
                        	</div>
                        </div>

                        <div className="col-md-4">
                        	<div className="item">
	                            <h4>Engineering </h4>
	                            <p>We have a licensed engineer on our teams to be sure that those who need a permit meet all state requirements, guaranteed. </p>
	                            <i className="material-icons">description</i>
                        	</div>
                        </div>

                        <div className="col-md-4">
                        	<div className="item">
	                            <h4>Do it your-self kits </h4>
	                            <p>Do you prefer to be in control of your install? We offer kits for those who are handy. Kits are also a way to avoid normal wait times. </p>
	                            <i className="material-icons">touch_app</i>
                        	</div>
                        </div>

                        <div className="col-md-4">
                        	<div className="item">
	                            <h4>Metal Building Supplies </h4>
	                            <p>We manufacture several sizes of tubular steel. We create all trim pieces and roll form sheet metal for all your metal roofing needs. </p>
	                            <i className="material-icons">shopping_cart</i>
                        	</div>
                        </div>
                    </div>
                </div>

                <div className="state-section container">
                    <div className="row">
                        <div className="col-md-7 left">
                            <div className="state">
                                <img src={ stateImg } alt="State Image" />
                            </div>

                            <div className="content">
                                <h2>{ state }</h2>
                                <span>Make Selection Here:</span>
                                <label>
                                <select onChange={ (e) => this.change_state(e) }>
                                    <option aria-label="select state"  value="">Pick State</option>
                                    {
                                        states.map((item, i) => {
                                            return (
                                                <option key={`key-${i}`} value={ item[0] } selected={ item[0] == state ? "selected" : "" } >
                                                    { item[0] }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                </label>
                            </div>
                        </div>    

                        <div className="col-md-5 right">
                            <h4><ReactImageFallback src={ phoneIcon } fallbackImage={ phoneIcon2 } initialImage="loader.gif" alt="Phone Icon" />  Local Phone Directory</h4>
                            <p><span>Toll Free:</span><span>1-888-822-7767</span></p>
                            {
                                Object.keys(localNumbers).map((key) => {
                                    if( key == state ) {
                                        return (
                                            <p key={`key-${key}`}><span>{ state } Local:</span><span>{ localNumbers[key] }</span></p>
                                        )
                                    }
                                        
                                })
                            }
                            
                        </div>
                    </div>    
                </div>

                <div id="steel_buildings_section" className="section-space">
                    <div className="container">
                        <div>
                            <ReactImageFallback alt="Red-Shop" src={ bigred } fallbackImage={bigred2} initialImage="loader.gif" />
                        </div>

                        <div className="content align-center">
                            <h2>Steel Buildings</h2>
                            
                            <a href="/steel_buildings">
                            <button aria-label="More Ideas">
                               
                                    <img alt="sun" src={ sunny } />
                                    CLICK HERE TO BROWSE DIFFERENT IDEAS
                                
                            </button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="why-choose-section">
                    <div className="row">
                        <div className="col-md-6 left">
                            <ReactImageFallback src={ garageGroup} fallbackImage={ garageGroup2 } initialImage="loader.gif" alt="Why Choose Section" />
                        </div>    

                        <div className="col-md-6 right">
                            <div className="section-title">
                                <h4>Why <span>Choose us?</span></h4>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h6><span>1.</span> Experienced</h6>
                                    <p>Talk to our reps and get expert tips on how to accomplish your building goals.</p>
                                </div>

                                <div className="col-md-6">
                                    <h6><span>2.</span> Established</h6>
                                    <p>We have a multi million dollar factory processing millions of pounds of steel yearly. </p>
                                </div>

                                <div className="col-md-6">
                                    <h6><span>3.</span> Tested and approved</h6>
                                    <p>Did you watch the special the weather channel did on our products? 
                                        <a className="cursor-pointer" 
                                            onClick={ () => this.setState({ chooseVideoModal: !this.state.chooseVideoModal }) }>
                                            <font color="#3694F4"> Click here to watch now</font>
                                        </a>
                                    </p>

                                    <Modal isOpen={ this.state.chooseVideoModal } toggle={ () => this.setState({ chooseVideoModal: !this.state.chooseVideoModal }) } >
                                        <ModalBody className="modal-body">
                                            <ReactPlayer url="https://www.youtube.com/watch?v=E3fQdlzrv8A&feature=emb_logo" playing loop />
                                        </ModalBody>
                                    </Modal>
                                </div>

                                <div className="col-md-6">
                                    <h6><span>4.</span> Warranty</h6>
                                    <p>Our certified designs come backed by our 20 year warranty. Pick a shop who is established and who stands behind their work. </p>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>

                <div id="rv_covers_section" className="container section-space">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="img">
                                <ReactImageFallback src={ rvcover1 } fallbackImage={ rvcover1fb } initialImage="loader.gif" alt="RV Covers 1" />
                            </div>

                            <div className="content">
                                <a href="/rv_covers">
                                    <h4><b>R.V Covers</b></h4>
                                </a>
                                <p className="txt-color-1"><b>Have you ever checked out the bill to repair the roof of an R.V? R.V Covers are worth every penny.</b></p>
                                <a href="/rv_covers">
                                <button aria-label="more designs" className="btn-style-1">
                                    
                                        CLICK HERE TO CHECK OUT MORE DESIGNS
                                        <i className="material-icons">keyboard_arrow_right</i>
                                    
                                </button>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-5 expand-view">
                            <div className="img">
                                <ReactImageFallback src={ rvcover2 } fallbackImage={rvcover2fb} initialImage="loader.gif" alt="RV Covers 2" />
                            </div>
                        </div>
                    </div>

                    <div className="row section-space gallery">
                        <div className="col-md-6">
                            <div className="item">
                                <div>
                                    <a href="/barns">
                                    <ReactImageFallback src={ barn1 } fallbackImage={ barn1fb } initialImage="loader.gif" alt="Barns" />
                                    </a>
                                </div>
                                <div className="content">
                                    <a href="/barns">
                                        <h4><b>Barns</b></h4>
                                    </a>
                                    <p>Check out our raised center barn setups. Browse the continuous roof style designs</p>
                                    <button aria-label="Browse Barns" className="btn-style-2">
                                        <a href="/barns">CLICK HERE TO BROWSE OUR BARNS</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item">
                                <div>
                                    <a href="/run_in_sheds">
                                        <ReactImageFallback src={ runin1 } fallbackImage={ runin1fb } initialImage="loader.gif" alt="Sheds" />
                                    </a>
                                </div>
                                <div className="content">
                                    <a href="/run_in_sheds">
                                        <h4><b>Run-in sheds</b></h4>
                                    </a>
                                    <p>Perfect for the pasture and the budget!</p>
                                    <button aria-label="Browse Run in sheds" className="btn-style-2">
                                        <a href="/run_in_sheds">CLICK HERE TO BROWSE RUN IN SHEDS</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="latest-section container">
                    <div className="section-title">
                        <h4>Latest <span>Projects</span></h4>
                        <p>We get jobs done every week, nation wide.</p>
                    </div>

                    <div className="row expand-view">
                        <div className="col-md-4">
                            <ReactImageFallback src={ late1 } fallbackImage = { late1fb } initialImage="loader.gif" alt="Latest Project" />
                        </div>

                        <div className="col-md-4">
                            <ReactImageFallback src={ late2 } fallbackImage = { late2fb } initialImage="loader.gif" alt="Latest Project" />
                        </div>

                        <div className="col-md-4">
                            <ReactImageFallback src={ late3 } fallbackImage = { late3fb } initialImage="loader.gif" alt="Latest Project" />
                        </div>

                        <div className="col-md-4">
                            <ReactImageFallback src={ late4 } fallbackImage = { late4fb } initialImage="loader.gif" alt="Latest Project" />
                        </div>

                        <div className="col-md-4">
                            <ReactImageFallback src={ late5 } fallbackImage = { late5fb } initialImage="loader.gif" alt="Latest Project" />
                        </div>

                        <div className="col-md-4">
                            <ReactImageFallback src={ late6 } fallbackImage = { late6fb } alt="Latest Project" />
                        </div>
                    </div>

                    <div className="row mobile-view">
                        {
                            this.latestItems.map((item, i) => {
                                if( i < 4 * this.state.latestMoreCount ) {
                                    return (
                                        <div key={`key-${i}`} className="col-md-4">
                                            <img src={ item[0] } alt="Latest Image" />
                                        </div>
                                    )
                                }
                                
                            })
                        }

                        <div className={this.state.latestMoreBtnVisible ? "more-btn-div" : "more-btn-div hidden"}>
                            <button aria-label="see more" className="more-btn" onClick={() => this.latest_more()}>
                                <i className="material-icons">more_horiz</i>More
                            </button>
                        </div>
                    </div>
                    
                </div>

                <div id="extra_wide_structures" className="container section-space">
                    <div>
                        <a href="/extra_wide">
                            <ReactImageFallback src={ extrawider } fallbackImage= {extrawiderfb} alt="Extra Wide Structure" />
                        <div className="bg"></div>
                        </a>
                    </div>

                    <div className="content">
                    <button aria-label="See More Exrra Wides" >
                    <a href="/extra_wide">
                        <h2>Extra wide structures</h2>
                        </a>
                        </button>
                        
                        
                    </div>
                </div>

                <div id="customize_section" className="container section-space">
                    <div className="row">
                        <div className="col-md-6">
                            <AliceCarousel
                                items={customUnitsSliderHtml}
                                buttonsDisabled={true}
                                responsive={this.responsive_custom_units}
                                ref={ el => this.CarouselCustomUnits = el }
                            />
                        </div>

                        <div className="col-md-6 right">
                            <a href="/customize">
                                <h4 className="color-blue-1"><b>Customize</b></h4>
                                </a>
                            <p className="txt-color-1"><b>Browse one of a kind custom units tailored to the needs of our clients</b></p>
                            <button aria-label="See customized designs" className="btn-style-2">
                                <a href="/customize" className="color-blue-1">
                                    CLICK HERE TO BROWSE CUSTOMIZED UNITS
                                    <i className="material-icons">keyboard_arrow_right</i>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>

                {/* <SpecialOffer /> */}

                <FinanceAndCoupon />

                <div id="watch_video_section" className="container section-space">
                    <div className="img">
                        <ReactImageFallback src={ watchvideo } fallbackImage={ watchvideofb } alt="Watch Video Now" />
                    </div>

                    <div className="content">
                        <h4><i className="material-icons">play_circle_filled</i> <b>Watch Videos Now</b></h4>
                        <p><b>Browse dozens of videos of our products to get a virtual tour into what’s available</b></p>
                        <button aria-label="Watch Videos" ><a href="/videos">CLICK HERE TO WATCH VIDEOS</a></button>
                    </div>
                </div>

                <div id="build_online_section" className="container section-space">
                    <div>
                        <div className="bg"></div>

                        <div className="row padding-0-50">
                            <div className="col-md-7 expand-view">
                                <div className="left">
                                    <p><i className="material-icons">done</i> Select door placement.</p>
                                    <p><i className="material-icons">done</i> Compare prices from the different shops we work with.</p>
                                    <p><i className="material-icons">done</i> Industry leading quoting tool.</p>
                                    <p><i className="material-icons">done</i> Register and save quotes.</p>
                                    <p><i className="material-icons">done</i> Email your self quotes with itemized details.</p>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="right">
                                    <ReactImageFallback src={ twoD } fallbackImage={ twoDfb } alt="2D and 3D structure" />
                                    <p onClick={ () => this.openOnlineBuildModal() } >Click here to build online in 2d and 3d.</p>
                                </div>
                            </div>

                            <div className="left bottom mobile-view">
                                <p><i className="material-icons">done</i> Select door placement.</p>
                                <p><i className="material-icons">done</i> Compare prices from the different shops we work with.</p>
                                <p><i className="material-icons">done</i> Industry leading quoting tool.</p>
                                <p><i className="material-icons">done</i> Register and save quotes.</p>
                                <p><i className="material-icons">done</i> Email your self quotes with itemized details.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brands-section section-space">
                    <div className="container">
                        <button aria-label="move images" onClick={() => this.Carousel2._slidePrev()} className="left-arrow" aria-label="Left Arrow">
                            <ReactImageFallback src={ left_arrow } initialImage="loader.gif" fallbackImage={ leftArrowFallBack } alt="Left Arrow" />
                        </button>
                        
                        <button  aria-label="move images" onClick={() => this.Carousel2._slideNext()} className="right-arrow" aria-label="Right Arrow">
                              <ReactImageFallback src={ right_arrow } initialImage="loader.gif" fallbackImage={ rightArrowFallBack } alt="Right Arrow" />
                        </button>
                        
                        <AliceCarousel
                            items={brandsHtml}
                            dotsDisabled={true}
                            buttonsDisabled={true}
                            responsive={this.responsive_2}
                            ref={ el => this.Carousel2 = el }
                            autoPlay={true}
                            autoPlayInterval={4000}
                        />
                    </div>

                    <p className="align-center">We’ve done jobs for these familiar companies, let us help you!</p>
                </div>

            </main>
        );
    }
}

/*
const geoWrapper = geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(HomePage);

const alertWrapper = withAlert(geoWrapper)

const mapActionsToProps = {
    hello: printHelloWorld
}

const mapStateToProps = (state) => {
    return { message: state.data.message }
}

export default alertWrapper;*/
// export default connect( mapStateToProps, mapActionsToProps )( HomePage );

export default HomePage;
