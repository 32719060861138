import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactPlayer from 'react-player';
import ReactImageFallback from "react-image-fallback";

import tntlogo from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogofb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import tntMetalLogo from '../../assets/images/Disclaimer/tnt-metal-buildings.webp';
import tntMetalLogofb from '../../assets/imagesSlow/Disclaimer/tnt-metal-buildings.bmp';
import triLogo from '../../assets/images/Disclaimer/tristate.webp';
import triLogofb from '../../assets/imagesSlow/Disclaimer/tristate.bmp';
import westLogo from '../../assets/images/Disclaimer/westcoast.webp';
import westLogofb from '../../assets/imagesSlow/Disclaimer/westcoast.bmp';
import amerLogo from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amerLogofb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import coastLogo from '../../assets/images/Disclaimer/coast-to-coast.webp';
import coastLogofb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import interLogo from '../../assets/images/Disclaimer/interstate.webp';
import interLogofb from '../../assets/imagesSlow/Disclaimer/interstate.bmp';

import car111 from '../../assets/images/Testimonials/carports-111.webp';
import car111fb from '../../assets/imagesSlow/Testimonials/carports-111.jpg';
import car112 from '../../assets/images/Testimonials/carport-112.webp';
import car112fb from '../../assets/imagesSlow/Testimonials/carport-112.jpg';
import car113 from '../../assets/images/Testimonials/carport-113.webp';
import car113fb from '../../assets/imagesSlow/Testimonials/carport-113.jpg';
import car878 from '../../assets/images/Testimonials/carport-878.webp';
import car878fb from '../../assets/imagesSlow/Testimonials/carport-878.jpg';
import car879 from '../../assets/images/Testimonials/carport-879.webp';
import car879fb from '../../assets/imagesSlow/Testimonials/carport-879.jpg';
import car880 from '../../assets/images/Testimonials/carport-880.webp';
import car880fb from '../../assets/imagesSlow/Testimonials/carport-880.jpg';
import car881 from '../../assets/images/Testimonials/carport-881.webp';
import car881fb from '../../assets/imagesSlow/Testimonials/carport-881.jpg';
import carp111 from '../../assets/images/Testimonials/car_port_111.webp';
import carp111fb from '../../assets/imagesSlow/Testimonials/car_port_111.jpg';
import carp112 from '../../assets/images/Testimonials/car_port_112.webp';
import carp112fb from '../../assets/imagesSlow/Testimonials/car_port_112.jpg';
import carp113 from '../../assets/images/Testimonials/car_port_113.webp';
import carp113fb from '../../assets/imagesSlow/Testimonials/car_port_113.jpg';
import ds from '../../assets/images/Testimonials/dsc00015.webp';
import dsfb from '../../assets/imagesSlow/Testimonials/dsc00015.jpg';
import me from '../../assets/images/Testimonials/metal_garage_40.webp'
import mefb from '../../assets/imagesSlow/Testimonials/metal_garage_40.jpg'

                  

import states from '../../assets/data/states.json';
import OnlineBuild from '../common/OnlineBuild.js';

class Testimonials extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state : "North Carolina",
            buildModal: false,
            buildFName: "",
            buildLName: "",
            buildPhone: "",
            buildEmail: "",
            buildState: "",
            isAgreeBuild: true,
            vidoe_1: false,
            vidoe_2: false,
            vidoe_3: false,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="testimonials" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>Meet Our Customers</h2>

                    <p>Take a look at a few of our happy customers that have sent us some photos!  Carports.com is there for you after your receive your product, our customers speak for us...</p>
                    <p>After you buy from Carports.com, send us your own testimonial!</p>

                    <div>
                        <h4 className="mb-0"><font color="#276095">Emailed Testimonials From Customers</font></h4>
                        <p>(Our owner follows up with each customer after delivery and installation)</p>

                        <div className="mt-30">
                            <p>Thanks for the email.  The installation was completed to satisfaction and at the present time there has not been any issues.  It was nice doing business with your company and will keep you in mind for any other future purchases that fall within your arena. - United States Marine Corps, Directorate of Emergency Services</p>
                            <hr />

                            <p>Thank you for the follow up, so few companies take the time or even care what happens to their products.  Yes indeed, the unit is set up and I am completely satisfied with it.  The materials are first rate and everything fit perfectly.  As an engineer (retired) I appreciate the thoughtful design that went into it.  Again, thanks for your concern and keep up the good work. - T. Amlick</p>
                            <hr />

                            <p>I ordered on 1/14/08 and it was installed on 1/24/08.  The guys were very efficient and professional.  Thanks for the quick service.  Ron - Crisp County School System</p>
                            <hr />

                            <p>We ordered 2 structures from you, 1 garage/shop, and a car cover.  Both have been installed and we are very Happy to have both units.  The Installers were Great also !  Thank You !!   Rick O.</p>
                            <hr />

                            <p>Yes they installed it early and fast. I'm interested to see about adding a shed to one side 26' wide by the full depth.  Sent from my BlackBerry® wireless handheld</p>
                            <hr />

                            <p>Thank you!  I am very pleased with the carport.  It was installed about a month ago.  Thank you for your time to follow up with us. - John</p>
                            <hr />

                            <p>The Group doing the install were VERY fast.  The installation was great and there were no issues with the product. - David</p>
                            <hr />

                            <p>My Carport was installed yesterday evening.  The delivery/installation crew was courteous, efficient, and helpful.  Thank you for the good service. - Andre</p>
                            <hr />

                            <p>Yes, our cover is for covering our emergency vehicle located at our Charleston plant site. - Mike</p>
                            <hr />

                            <p>Our carport structure has been installed and it is wonderful!  Our teachers and students appreciate the shaded covering. - International School of Louisiana.</p>
                            <hr />

                            <p>Thanks for the email, I have received my carport/garage and it looks great. - Max Power Electrical</p>
                            <hr />

                            <p>My carport has been installed.  The installation crew did a great job and I was really impressed with how fast they erected it.  Also, I was impressed with the cleanup after the job.  My experience with your company was exceptional and I would and have recommended your company to several friends and neighbors.  Thanks for your concern and for taking time to follow up. - Todd, Star Mechanical Supply</p>
                            <hr />

                            <p>Yes, it has (been installed) they came Thursday and they did not leave until they were done.  Thanks for checking.</p>
                            <hr />

                            <p>Carport was installed Tuesday.  Installers did an excellent job.  I paid in full on Wednesday.</p>
                            <hr />

                            <p>Yes, it was installed in 1 1/2 hours.  I was very impressed; their teamwork is like clockwork; well coordinated, focused and quiet.  I took lots of pictures and will be adding this story to my website. - Van</p>
                            <hr />

                            <p>It has been installed.  It was done very professionally and my end user liked it so much that I believe he will purchase a few more from you. - Bart, Americas Computer Company</p>
                            <hr />

                            <p>Thank you for the follow-up - it is seldom done anymore.  Yes the carport is installed and great satisfaction.  It is just what I expected and I am pleased. Thank you again. - Steve</p>
                            <hr />

                            <p>We have had our carport installed.  We are very happy with the structure and my husband's boat is happy to be covered. - Amanda</p>
                            <hr />

                            <p>Yes, our carport was installed the next week after we placed our order.  We are very pleased with the workmanship and the promptness of the installation.  We would recommend your company to all our friends. - Edwin & Sonja</p>
                            <hr />

                            <p>Yes, it has been installed and we love it.  Thank you for checking. - Chris</p>
                            <hr />

                            <p>Yes, my carport has been installed (took them about an hour!) the color is perfect, the price was perfect, everyone at your company is great, I am a very happy camper!!!! I will definitely be recommending you guys to all my friends and family if they need a carport or garage.  In fact I will tell them not to look any farther than your company!!! - A Customer For Life!, James</p>
                            <hr />

                            <p>Yes, it was installed before Christmas, and the guys did an excellent job I must say everything went as planned and so far I am very satisfied with your product. - B. Seymour</p>
                            <hr />

                            <p>Yes we are very satisfied, the installers were great and we recommend you to everyone!  - Sincerely James</p>
                            <hr />

                            <p>The Carport was installed in a timely and professional manner.  I am very pleased with it. - Jake</p>
                            <hr />

                            <p>Yes, we did receive our new carport right after the first of the year.  We are very pleased with it.  Thank you for your personal attention when trying to order the carport.  You helped me with the dimensions and the cost adjustments.  That is one of the reasons why we bought from you!  It is exactly what we wanted! - Maura</p>
                            <hr />

                            <p>I must tell you, the guy's who installed my carport were very professional, very efficient, and where more than willing to answer all my questions.  They had the carport up and cleaned up after themselves in a matter of a couple of hours.  They are good workers.  I wouldn't let them go.  As far the carport, it is working out very well.  It is doing exactly what I needed it to do, protect my new vehicle.  It is a great piece of work.  THANK YOU, from a very pleased customer. - Jim</p>
                            <hr />

                            <p>Hello there, just wanted to let you know your installers did a great job putting this thing up.  Wind was howling all day, and sure gave them a rough time, but they got it done without incident.  I'll give you a call in the morning (Wednesday) to take care of the billing.  If you could, I'd like a receipt showing the taxes as I write my taxes off. - Douglas</p>

                        </div>
                    </div>
                    <hr />

                    <div className="mt-50 mb-50">
                        <h4 className=""><font color="#276095">Customer Testimonial Videos</font></h4>

                        <p className="mb-0 cursor-pointer">
                            <a onClick={ () => this.setState({ vidoe_1: !this.state.vidoe_1 }) }>1. Testimonial from customer - Jason Moxley, Charleston, West Virginia</a>
                        </p>
                        <p className="mb-0 cursor-pointer">
                            <a onClick={ () => this.setState({ vidoe_1: !this.state.vidoe_1 }) }>2. Testimonial from customer - Sherry & Franklin Hash, Sparta, North Carolina</a>
                        </p>
                        <p className="mb-0 cursor-pointer">
                            <a onClick={ () => this.setState({ vidoe_1: !this.state.vidoe_1 }) }>3. Testimonial from customer - Pat Bennett</a>
                        </p>

                        <Modal isOpen={ this.state.vidoe_1 } toggle={ () => this.setState({ vidoe_1: !this.state.vidoe_1 }) } >
                            <ModalBody className="modal-body">
                                <ReactPlayer url="https://vimeo.com/274359090" playing loop />
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={ this.state.vidoe_2 } toggle={ () => this.setState({ vidoe_1: !this.state.vidoe_2 }) } >
                            <ModalBody className="modal-body">
                                <ReactPlayer url="https://vimeo.com/274359090" playing loop />
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={ this.state.vidoe_3 } toggle={ () => this.setState({ vidoe_1: !this.state.vidoe_3 }) } >
                            <ModalBody className="modal-body">
                                <ReactPlayer url="https://vimeo.com/274359090" playing loop />
                            </ModalBody>
                        </Modal>
                    </div>
                    <hr />

                    <div>
                        <h4 className="mt-50 mb-50"><font color="#276095">Customer Submitted Photos</font></h4>

                        <div className="section">
                            <div className="border-2 align-center padding-20-0 imgs">
                                <h5>30 x 20 x 8, Type II Truss, Brown.</h5>

                                <ReactImageFallback src={ car111 } fallbackImage={car111fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ car112 } fallbackImage={car112fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ car112 } fallbackImage={car112fb} initialImage="loader.gif" alt="Tnt logo" />
                            </div>

                            <div className="mt-10 mb-30">
                                <p className="blue-color mb-0"><b>Questions or want a price on a product you see?</b></p>
                                <p>Simply contact us, <span className="danger-color underline cursor-pointer" onClick={ () => this.openOnlineBuildModal() }>click here</span> to find your local state number.</p>
                            </div>

                            <div className="reviews">
                                <p class="border-2 mb-30"><i>Just wanted to let you know they installed my carport RV-Port yesterday 8/8/07. It turned out really nice, and the 3-guys installing it were excellent,
                            very friendly and polite. I can't believe it only took them 3-hours to put it up.
                            That's quick to put up an 18' X 36' X 12' high structure. I took pictures of them
                            putting it up, if you would like to see them just e-mail me and I'll send them
                            to you. <br /><br />Thank-you,<br />Clifford/Oklahoma</i></p>

                                <p class="border-2 mb-30"><i>Hello... <br /><br />My garage was installed yesterday & I must say it looks GREAT. The crew completed the job in a very professional manor & I would recommend your
                            product to anyone. Thanks again for all of the help your help & I look forward
                            to doing business with you again.<br /><br />Shawn/West Virginia.</i></p>
                            </div>
                        </div>

                        <div className="section">
                            <div className="border-2 align-center padding-20-0 imgs">
                                <h5>20 x 20 x 7 Carport</h5>

                                 <ReactImageFallback src={ car878 } fallbackImage={car878fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ car879 } fallbackImage={car879fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ car880 } fallbackImage={car880fb} initialImage="loader.gif" alt="Tnt logo" />
                                 <ReactImageFallback src={ car881 } fallbackImage={car881fb} initialImage="loader.gif" alt="Tnt logo" />
                         
                            </div>

                            <div className="mt-10 mb-30">
                                <p className="blue-color mb-0"><b>Questions or want a price on a product you see?</b></p>
                                <p>Simply contact us, <span className="danger-color underline cursor-pointer" onClick={ () => this.openOnlineBuildModal() }>click here</span> to find your local state number.</p>
                            </div>
                        </div>

                        <div className="section">
                            <div className="border-2 align-center padding-20-0 imgs">
                                <h5 className="pl-20 pr-20">24 x 24'6 x 10 Garage with Two 30 x 30 windows, One Walk-in Door Opening & Two Garage Door Openings (doors sold separately)</h5>

                                <ReactImageFallback src={ ds } fallbackImage={dsfb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ me } fallbackImage={mefb} initialImage="loader.gif" alt="Tnt logo" />
                            </div>

                            <div className="border-2 align-center padding-20-0 imgs mt-50">
                                <h5>12 x 24.6 x 7 Carport</h5>

                               <ReactImageFallback src={ carp111 } fallbackImage={carp111fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ carp112 } fallbackImage={carp112fb} initialImage="loader.gif" alt="Tnt logo" />
                                <ReactImageFallback src={ carp113 } fallbackImage={carp113fb} initialImage="loader.gif" alt="Tnt logo" />
                                 
                            </div>

                            <div className="mt-10 mb-30">
                                <p className="blue-color mb-0"><b>Questions or want a price on a product you see?</b></p>
                                <p>Simply contact us, <span className="danger-color underline cursor-pointer" onClick={ () => this.openOnlineBuildModal() }>click here</span> to find your local state number.</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <h5 className="color-red"><i>Why Carports.com?</i></h5>

                        <p>We cover more than 150,000,000 square feet (every year) with metal carports, garages, horse barns and other outdoor covers.  We have any option you're looking for, along with the name and brand you can trust.  When you order a product that will last for years, you want a company that will be there for you, support the product you buy and stand behind it.  You can count on Carports.com and our friendly staff to provide A+ service.</p>

                        <ul>
                            <li>Meet our happy customers!</li>
                            <li>Meet & contact our friendly staff.</li>
                            <li>View videos of our structures online.</li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Sizes Available:</i></h5>

                        <p className="mb-0"><b>Carports & Covers:</b> 7' to 40' Wide</p>
                        <p className="pl-50">Up to 12' Tall, Any Length!</p>

                        <p className="mb-0"><b>Garages & Buildings:</b> 12' to 40' Wide</p>
                        <p className="pl-50">Up to 16' Tall, Any Length!</p>

                        <p><b>Got A Custom Project?</b> We work with thousands of customers, each project is different.  Leave it to our experts to assist you in finding what will fit your needs.  Call us, we specialize in custom built outdoor covers.</p>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Kits or Installation?</i></h5>

                        <p><b>You pick!</b> We carry both options for you, depending on what your need is, which brand your shopping for, etc.</p>
                    </div>

                    <div className="brands">
                        <h5 className="color-red"><i>Featured Brands...</i></h5>

                         <div className="align-center">
                            <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                            <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                             <ReactImageFallback src={ westLogo } fallbackImage={westLogofb} initialImage="loader.gif" alt="West Coast carports logo" />
                      
                        </div>

                        <div className="align-center">
                            <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />
                            <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Coast to Coast logo" />
                            <ReactImageFallback src={ interLogo } fallbackImage={interLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                        </div>
                    </div>
                </div>

                <OnlineBuild />
            </main>
        );
    }
}

export default Testimonials;
