import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import ReactImageFallback from "react-image-fallback";

import phicon from '../../assets/images/phone_icon_3.webp';
import phiconfb from '../../assets/imagesSlow/phone_icon_3.png';


import localNumbers from '../../assets/data/local_number.json';
import states from '../../assets/data/states.json';


class SpecialOffer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state: "Pick Your State",

        };

    }

    change_state(event) {
        if( event.target.value != "" ) {
            this.setState({ state : event.target.value });
            window.state = event.target.value;
        }
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        const stateImg = '/imagesSlow/states/' + state + '.jpg';

        return (
            <div className="state-section container">
                <div className="row">
                    <div className="col-md-6 right margin-auto">
                        <h4> <ReactImageFallback src={ phicon } fallbackImage={phiconfb} initialImage="loader.gif" alt="Tnt logo" />  Local Phone Directory</h4>
                        <p><span>Toll Free:</span><span>1-888-822-7767</span></p>
                        {
                            Object.keys(localNumbers).map((key) => {
                                if( key == state ) {
                                    return (
                                        <p key={`key-${key}`}><span>{ state } Local:</span><span>{ localNumbers[key] }</span></p>
                                    )
                                }
                                    
                            })
                        }
                    </div>
                    <div className="col-md-12 left">
                        <div>
                            <img className="state" src={ stateImg } alt="State Image" />

                            <div className="content">
                                <h2>{ state } Sales</h2>
                                <span>Please choose correct state:</span>
                                <select onChange={ (e) => this.change_state(e) }>
                                    <option value="">Pick State</option>
                                    {
                                        states.map((item, i) => {
                                            return (
                                                <option key={`key-${i}`} value={ item[0] } selected={ item[0] == state ? "selected" : "" } >
                                                    { item[0] }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>    
                </div>    
            </div>
        );
    }
}

export default SpecialOffer;
