import { PRINT_HELLO } from '../actions/printHelloAction';

const printHelloWorldReducer = ( state={message: "No message"}, { type, payload } ) => {
    switch( type ) {
        case PRINT_HELLO:
            return payload;
        default:
            return state;
    }
}

export default printHelloWorldReducer;