import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './components/pages/Homepage';
import Agricultural from './components/pages/Agricultural';
import Carports from './components/pages/Carports';
import SteelBuildings from './components/pages/SteelBuildings';
import LeanTos from './components/pages/LeanTos';
import RVCovers from './components/pages/RVCovers';
import RuninSheds from './components/pages/RuninSheds';
import Barns from './components/pages/Barns';
import ExtraWide from './components/pages/ExtraWide';
import Customize from './components/pages/Customize';
import Disclosures from './components/pages/Disclosures';
import Disclaimer from './components/pages/Disclaimer';
import Coupons from './components/pages/Coupons';
import Comparison from './components/pages/Comparison';
import ReturnPolicy from './components/pages/ReturnPolicy';
import ColorCharts from './components/pages/ColorCharts';
import Faq from './components/pages/Faq';
import Testimonials from './components/pages/Testimonials';
import Videos from './components/pages/Videos';
import InstallationMaual from './components/pages/InstallationMaual';
import AboutUs from './components/pages/AboutUs';


const Router = () => (

    <Switch>
        <Route exact path='/' component={ HomePage } />
        <Route path='/agricultural' component={ Agricultural } />
        <Route path='/carports' component={ Carports } />
        <Route path='/steel_buildings' component={ SteelBuildings } />
        <Route path='/lean_tos' component={ LeanTos } />
        <Route path='/rv_covers' component={ RVCovers } />
        <Route path='/run_in_sheds' component={ RuninSheds } />
        <Route path='/barns' component={ Barns } />
        <Route path='/customize' component={ Customize } />
        <Route path='/extra_wide' component={ ExtraWide } />
        <Route path='/disclosures' component={ Disclosures } />
        <Route path='/disclaimer' component={ Disclaimer } />
        <Route path='/coupons' component={ Coupons } />
        <Route path='/comparison' component={ Comparison } />
        <Route path='/return_policy' component={ ReturnPolicy } />
        <Route path='/color_charts' component={ ColorCharts } />
        <Route path='/faq' component={ Faq } />
        <Route path='/testimonials' component={ Testimonials } />
        <Route path='/videos' component={ Videos } />
        <Route path='/installation_maual' component={ InstallationMaual } />
        <Route path='/AboutUs' component={ AboutUs } />
    </Switch>
    
)

export default Router