import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import {geolocated} from 'react-geolocated';
import { withAlert } from 'react-alert';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

import offer_1 from '../../assets/imagesSlow/barnFront.png';
import offer_2 from '../../assets/imagesSlow/shopoverhang.png';
import offer_3 from '../../assets/imagesSlow/001BB.png';
import offer_4 from '../../assets/imagesSlow/end view of existion.png';
import offer_5 from '../../assets/imagesSlow/garageWlean.png';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';
import left_arrowfb from '../../assets/imagesSlow/left_arrow.png';
import right_arrowfb from '../../assets/imagesSlow/right_arrow.png';
import style_1 from '../../assets/images/Agricultural/Image 23.webp';
import style_1fb from '../../assets/imagesSlow/Agricultural/Image 23.png';
import style_2 from '../../assets/images/Agricultural/Image 22.webp';
import style_2fb from '../../assets/imagesSlow/Agricultural/Image 22.png';
import style_3 from '../../assets/images/Agricultural/Image 26.webp';
import style_3fb from '../../assets/imagesSlow/Agricultural/Image 26.png';
import style_4 from '../../assets/images/Agricultural/Image 41.webp';
import style_4fb from '../../assets/imagesSlow/Agricultural/Image 41.png';
import style_5 from '../../assets/images/Agricultural/Image 43.webp';
import style_5fb from '../../assets/imagesSlow/Agricultural/Image 43.png';
import style_6 from '../../assets/images/Agricultural/Image 42.webp';
import style_6fb from '../../assets/imagesSlow/Agricultural/Image 42.png';
import investment_bg from '../../assets/images/Agricultural/barnBlock.webp';
import delivered_1 from '../../assets/images/Agricultural/Image 35.webp';
import delivered_1fb from '../../assets/imagesSlow/Agricultural/Image 35.png';
import delivered_2 from '../../assets/images/Agricultural/Image 36.webp';
import delivered_2fb from '../../assets/imagesSlow/Agricultural/Image 36.png';
import delivered_3 from '../../assets/images/Agricultural/Image 33.webp';
import delivered_3fb from '../../assets/imagesSlow/Agricultural/Image 33.png';
import delivered_4 from '../../assets/images/Agricultural/Image 34.webp';
import delivered_4fb from '../../assets/imagesSlow/Agricultural/Image 34.png';
import delivered_5 from '../../assets/images/Agricultural/Image 37.webp';
import delivered_5fb from '../../assets/imagesSlow/Agricultural/Image 37.png';
import delivered_6 from '../../assets/images/Agricultural/Image 38.webp';
import delivered_6fb from '../../assets/imagesSlow/Agricultural/Image 38.png';
import delivered_7 from '../../assets/images/Agricultural/Image 29.webp';
import delivered_7fb from '../../assets/imagesSlow/Agricultural/Image 29.png';
import delivered_8 from '../../assets/images/Agricultural/Image 30.webp';
import delivered_8fb from '../../assets/imagesSlow/Agricultural/Image 30.png';
import delivered_9 from '../../assets/images/Agricultural/Image 31.webp';
import delivered_9fb from '../../assets/imagesSlow/Agricultural/Image 31.png';
import delivered_10 from '../../assets/images/Agricultural/Image 32.webp';
import delivered_10fb from '../../assets/imagesSlow/Agricultural/Image 32.png';
import banner_mark from '../../assets/images/Agricultural/Image 40.webp';
import banner_markfb from '../../assets/imagesSlow/Agricultural/Image 40.png';

import states from '../../assets/data/states.json';
import SpecialOffer from '../common/SpecialOffer';
import OnlineBuild from '../common/OnlineBuild';
import FinanceAndCoupon from '../common/FinanceAndCoupon';

 
class Agricultural extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isCity: false,
            offerMoreCount : 1,
            offerMoreBtnVisible: true,
            fname: '',
            lname: '',
            phone: '',
            company: '',
            address: '',
            state: 'your city',
            mailSent: false,
            error: null,
            modal: false,
            setModal: false,
            weeklySpecials: false,
            weeklyFName: "",
            weeklyLName: "",
            weeklyPhone: "",
            weeklyEmail: "",
            weeklyState: "",
            isAgreeWeekly: true,
            buildModal: false,
            buildFName: "",
            buildLName: "",
            buildPhone: "",
            buildEmail: "",
            buildState: "",
            isAgreeBuild: true,
            buildModal_2: false,
            isAgreeBuild_2: true,

        };

    }
    

    responsive_1 = {
        0: { items: 1 },
        600: { items: 3 },
        1024: { items: 4 },
    };

    garagesItems = [ 
        [offer_1, 'Wedding Barn', '8900', 'Center section is 18 w x 100 L  x 16 H and the wings are both 12 x 100 x 13 down to 10. The open lean-tos are 12 x 100 x 7.'], 
        [offer_2, 'Garage with Overhangs', '8900', '24 x 40 x 10 garage with 20 enclosed and 20 open. One 12 x 20 lean-to '], 
        [offer_3, 'Display Lot Barn', '8900', 'Center section is 18 w x 30 L  x 16 H and the wings are both 12 x 30 x 13 down to 10.'], 
        [offer_4, 'Café  Table Cover', '8900', 'This 30 x 50 x 9 regular style carport cover works perfect out side for dining '],
        [offer_5, 'Garage with lean-to', '8900', 'This garage is 24 x 30 x 10 all enclosed with one 12 x 30 x 7 lean-to off the side wall'] 
    ];

    states = [ ["Alabama"], ["Alaska"], ["Arizona"], ["Arkansas"], ["California"], ["Colorado"], ["Connecticut"], 
        ["Delaware"], ["Florida"], ["Georgia"], ["Hawaii"], ["Idaho"], ["Illinois"], ["Indiana"], ["Iowa"], ["Kansas"], 
        ["Kentucky"], ["Louisiana"], ["Maine"], ["Maryland"], ["Massachusetts"], ["Michigan"], ["Minnesota"], ["Mississippi"], 
        ["Missouri"], ["Montana"], ["Nebraska"], ["Nevada"], ["New Hampshire"], ["New Jersey"], ["New Mexico"], ["New York"], 
        ["North Carolina"], ["North Dakota"], ["Ohio"], ["Oklahoma"], ["Oregon"], ["Pennsylvania"], ["Rhode Island"], 
        ["South Carolina"], ["South Dakota"], ["Tennessee"], ["Texas"], ["Utah"], ["Vermont"], ["Virginia"], ["Washington"], 
        ["West Virginia"], ["Wisconsin"], ["Wyoming"] ];

    galleryItem = (item, i) => (
        <div key={`key-${i}`} className="offer-item">
            <div className="position-relative">
                <img src={ item[0] } alt="Gallery Image" />
                <span className="water-mark">carports.com 888-822-7767</span>
            </div>
            
            <div className="content">
                <h4>{ item[1] }</h4>
                <p>{ item[3] }</p>
                <div className="read-more">
                    <a href="/installation_maual">Brochure</a> 
                    <button>On Sale Now!</button>
                </div>
            </div>
        </div>
    )

    offer_more() {
        this.setState({ offerMoreCount: this.state.offerMoreCount + 1 });
        
        if( this.garagesItems.length <= (this.state.offerMoreCount + 1) * 4 ) {
            this.setState({ offerMoreBtnVisible: false });
        }
    }

    handleFormSubmit( event ) {
        event.preventDefault();
        // console.log(this.state);

        // Adding 'https://cors-anywhere.herokuapp.com/' to the URL for not getting the CROS Origin Error
        const API_PATH = 'https://cors-anywhere.herokuapp.com/http://tubemetalshop.com/Dev/tube_metal_shop/api/index.php';

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: { 
                fname   : this.state.fname,
                lname   : this.state.lname,
                phone   : this.state.phone,
                company : this.state.company,
                address : this.state.address,
                state   : this.state.state,
            }
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent
            })

            if( result.data.sent == true ) {
                alert("Your info has been sent successfully.");
            } else {
                alert("You should fill out each fields.");
            }
        })
        .catch(error => {
            this.setState({ error: error.message });

            alert( error.message );
        });
    }

    openOnlineBuildModal() {
        window.onlineBuildModal = true;
        this.setState({ state4Render: true });
    }

    render() {
        const garagesItems = this.garagesItems.map(this.galleryItem);

        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="agricultural">
                <div className="banner">
                    <div className="row">
                        <div className="col-md-7 left">
                            <h2>Why use Carports.com in { state }?</h2>

                            <p><b>Fantastic Value</b> - Save up to 50% compared to stick built</p>

                            <p><b>Long Term</b> - Engineered galvanized steel tubing systems</p>

                            <p><b>Experinced</b> - 20 years of nation wide delivery and installation</p>

                            <p><b>Carports & Covers</b> - From 7' to 80 Wide Up to 20' Tall, Any Length!</p>

                            <p><b>Garages & Buildings</b> - Order a kit or have us do the installation.</p>

                            <p><b>Contractor</b> - We are a licensed contractor in your area!</p>

                            <div className="bottom">
                                <p>Thousands of customers around the country have trusted us to provide them with the best carports, garages, and service!  Thank you for making our company the #1 provider in your state.</p>

                                <ReactImageFallback className="banner-mark" src={ banner_mark } fallbackImage={banner_markfb} initialImage="loader.gif" alt="Banner Mark" />
                            </div>
                        </div>

                        <div className="col-md-5 right">
                            <form action="#" >
                                <div className="row">
                                    <div className="col-md-12 align-center">
                                       <h6 className="mb-20"><b>Order Brochure Now</b></h6>
                                    </div>
                                    
                                    <div className="col-md-6 pr-5">
                                        <input type="text" id="fname" name="firstname" placeholder="First Name"
                                            value={this.state.fname} aria-label="Enter your First Name"
                                            onChange={e => this.setState({ fname: e.target.value })}
                                        />
                                    </div>

                                    <div className="col-md-6 pl-5">
                                        <input type=" text" id="lname" name="lastname" placeholder="Last Name"
                                            value={this.state.lname} aria-label="Enter your Last Name"
                                            onChange={e => this.setState({ lname: e.target.value })}
                                        />
                                    </div>
                                
                                    <div className="col-md-12">
                                        <input type="text" id="phone" name="phone" placeholder="Phone Number"
                                            value={this.state.phone} aria-label="Enter your Phone Number"
                                            onChange={e => this.setState({ phone: e.target.value })}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" id="company" name="company" placeholder="Company Number"
                                            value={this.state.company} aria-label="Enter your Company"
                                            onChange={e => this.setState({ company: e.target.value })}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" id="address" name="address" placeholder="Address"
                                            value={this.state.address} aria-label="Enter your Address"
                                            onChange={e => this.setState({ address: e.target.value })}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <select className="pl-10">
                                        {
                                            this.states.map((item, i) => {
                                                return (
                                                    <option key={`key-${i}`} value={ item[0] } selected={ item[0] == state ? "selected" : "" } >
                                                        { item[0] }
                                                    </option>
                                                )
                                            })
                                        }
                                        </select>
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" id="zipcode" name="zipcode" placeholder="Zip Code" aria-label="Enter Zip Code"
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="submit" className="submit-btn" onClick={e => this.handleFormSubmit(e)} value="Submit" />
                                    </div>

                                    <div className="col-md-12 call-div align-center">
                                        <p>Or call now to speak to a specialist</p>
                                        <h6><b>1-888-822-7767</b></h6>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="style-section-1 container">
                    <div className="row">
                        <div className="col-md-6 left mb-20">
                            <ReactImageFallback src={ style_1 } fallbackImage={style_1fb} alt="Continuous Roof Truss Systems" initialImage="loader.gif" />

                            <div className="content">
                                <p>32 - 80 wide</p>
                                <h4>Continuous Roof Truss Systems</h4>
                            </div>
                        </div>

                        <div className="col-md-6 right mb-20">
                            <ReactImageFallback src={ style_2 } fallbackImage={style_2fb} alt="Raised Center Truss Systems" initialImage="loader.gif" />

                            <div className="content">
                                <p>32 - 80 wide</p>
                                <h4>Raised Center Truss Systems</h4>
                            </div>
                        </div>
                    </div>    
                </div>

                <div className="phone-number">
                    <p><span>1-888-822-7767</span></p>
                </div>

                <div className="style-section-2 container">
                    <div className="row">
                        <div className="col-md-6 left">
                            <ReactImageFallback src={ style_3 } fallbackImage={style_3fb} alt="Clear Span Truss System" initialImage="loader.gif" />

                            <div className="content">
                                <h4>Clear Span Truss System</h4>
                                <p>32 - 80 wide</p>
                            </div>
                        </div>

                        <div className="col-md-6 right">
                            <div className="row">
                                <div className="col-md-6">
                                    <ReactImageFallback src={ style_4 } alt="A Frame Style" fallbackImage={style_4fb} initialImage="loader.gif"/>

                                    <p>26-30 wide<br />A frame Style</p>
                                </div>

                                <div className="col-md-6">
                                    <ReactImageFallback  src={ style_5 } fallbackImage={style_5fb} alt="A Frame Style" initialImage="loader.gif"/>

                                    <p>12-26 wide<br />A frame Style</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <ReactImageFallback src={ style_6 } fallbackImage={ style_6fb } alt="Regular Style" initialImage="loader.gif" />

                                <p>12-26 wide<br />Regular Style</p>
                            </div> 
                        </div>
                    </div>    
                </div>

                <div className="offer-section">
                    <div className="section-title">
                        <h4>What barns are <span>Offered?</span></h4>
                        <p>Here are some ideas to help get you started.</p>
                    </div>

                    <div className="gallery-container expand-view">
                        <div className="container">
                            <div className="garages-gallery">
                                <button onClick={() => this.Carousel1._slidePrev()} className="left-arrow">
                                    <ReactImageFallback src={ left_arrow } fallbackImage={left_arrowfb} alt="Left Arrow" initialImage="loader.gif" />
                                </button>
                                
                                <button onClick={() => this.Carousel1._slideNext()} className="right-arrow">
                                    <ReactImageFallback src={ right_arrow } fallbackImage={right_arrowfb} alt="Right Arrow" initialImage="loader.gif" />
                                </button>
                                
                                <AliceCarousel
                                    items={garagesItems}
                                    dotsDisabled={true}
                                    buttonsDisabled={true}
                                    responsive={this.responsive_1}
                                    ref={ el => this.Carousel1 = el }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="alice-carousel mobile-view">
                        {
                            this.garagesItems.map((item, i) => {
                                if( i < 4 * this.state.offerMoreCount ) {
                                    return (
                                        <div key={`key-${i}`} className="offer-item">
                                            <img src={ item[0] } alt="Offer Image" />
                                            <div className="content">
                                                <h4>{ item[1] }</h4>
                                                <p>{ item[3] }</p>
                                                <div className="read-more">
                                                    <a href="#">Read More</a> 
                                                    <button>${ item[2] } <span>+ tax</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            })
                        }

                        <div className={this.state.offerMoreBtnVisible ? "more-btn-div" : "more-btn-div hidden"}>
                            <button className="more-btn" onClick={() => this.offer_more()}>
                                <i className="material-icons">more_horiz</i>More
                            </button>
                        </div>
                    </div>
                </div>

                <div className="investment-section">
                    <div className="container">
                        <h4><b>These are a great investment for any farm</b></h4>

                        <p>Our building systems can be fixed permanently  but they are also classified as portable because 
                            the building system’s anchors can be pulled up and the kit can be taken apart. Reinstall anywhere 
                            you’d like! <a onClick={ () => this.openOnlineBuildModal() }>Click here to get help on a quote.</a> </p>
                    </div>
                    
                </div>

                <div className="delivered-section container">
                    <h2>DELIVERED, INSTALLED & ANCHORED ON YOUR LEVEL LOT</h2>

                    <div className="row align-center">
                        <div className="col-md-4 mb-20">
                            <h4><b>Run-Shed</b></h4>

                            <ReactImageFallback src={ delivered_1 } fallbackImage={ delivered_1fb} initialImage="loader.gif" alt="Run Sheds" />

                            <ReactImageFallback src={ delivered_2 } fallbackImage={delivered_2fb} alt="Run Sheds" initialImage="loader.gif"  />
                        </div>

                        <div className="col-md-4 mb-20">
                            <h4><b>Raised Center Barn</b></h4>

                            <ReactImageFallback src={ delivered_3 } fallbackImage={ delivered_3fb} initialImage="loader.gif" alt="Raised Center Barn" />
                            
                            <ReactImageFallback src={ delivered_4 } fallbackImage={ delivered_4fb} initialImage="loader.gif" alt="Raised Centr Barn" />
                        </div>

                        <div className="col-md-4 mb-20">
                            <h4><b>Normal Truss</b></h4>

                            <ReactImageFallback src={ delivered_5 } fallbackImage={ delivered_5fb} initialImage="loader.gif" alt="Normal Truss" />
                            
                            <ReactImageFallback src={ delivered_6 } fallbackImage={ delivered_6fb} initialImage="loader.gif" alt="Normal Truss" />
                        </div>          
                    </div>

                    <div className="align-center mt-30 mb-30">
                        <button className="build-online-btn" onClick={ () => this.openOnlineBuildModal() }>BUILD ONLINE NOW</button>
                   </div>

                    <div className="row">
                        <div className="col-md-6">
                            <ReactImageFallback src={ delivered_7 } fallbackImage={ delivered_7fb} initialImage="loader.gif"alt="Free Standing Lean-to" />
                            
                            <p className="align-center mt-15"><b>Free standing Lean-to</b></p>
                        </div>

                        <div className="col-md-6">
                            <ReactImageFallback src={ delivered_8 } fallbackImage={ delivered_8fb} initialImage="loader.gif" alt="Horse Run-in Area" />
                            
                            <p className="align-center mt-15"><b>Horse Run-in Area</b></p>
                        </div>

                        <div className="col-md-6">
                            <ReactImageFallback src={ delivered_9 } fallbackImage={ delivered_9fb} initialImage="loader.gif" alt="Vertical Siding With 2 Tone" />
                            
                            <p className="align-center mt-15"><b>Vertical Siding with 2 Tone</b></p>
                        </div>

                        <div className="col-md-6">
                            <ReactImageFallback src={ delivered_10 } fallbackImage={ delivered_10fb} initialImage="loader.gif" alt="Horizontal Sided Raised Center" />
                            
                            <p className="align-center mt-15"><b>Horizontal Sided Raised Center</b></p>
                        </div>
                    </div>
                </div>

                {/* <SpecialOffer /> */}
                
                <FinanceAndCoupon />

                <div className="bottom-description-section align-center">
                    <div className="container">
                        <p><b>
                            Finding a quality build and long lasting horse barn is a very important decision for any horse owner. For all equine enthusiasts, their most prized possession is their horses. Most horses spend the majority of their time grazing in the pasture or standing in a barn or outdoor shelter.
                        </b></p>

                        <p><b>
                            At either location, they need reliable shelter. Many traditional barns have many qualities that could be hazardous to your horse, such as nails or wood splinters.  Metal barns are safe for even the most mischievous animals.  The initial cost of a steel horse barn is well worth the return on your investment, as well as a healthy, safe environment for your family companion or equine business.
                        </b></p>

                        <p><b>
                            Horses can be very destructive with their compulsive behavior of cribbing.  This habit can be solved easily without the aid of expensive “chew stop” products or irritating cribbing collars.  There is one simple solution, a galvanized steel metal barn.  Barns.com offers many different styles of horse barns that can be customized to meet your specifications.
                        </b></p>

                        <p><b>
                            Call us for a written price quote guarantee at 888-812-2767 
                        </b></p>
                    </div>
                </div>

                <OnlineBuild />

            </main>
        );
    }
}
/*
const geoWrapper = geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })(Agricultural);

const alertWrapper = withAlert(geoWrapper) 

export default alertWrapper;*/

export default Agricultural;
