import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Collapse, CardBody, Card } from 'reactstrap';

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';

const sortedGalleryData = [];
 
class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isOpen_1: false,
            isOpen_2: false,
            isOpen_3: false,
            isOpen_4: false,
            isOpen_5: false,
            isOpen_6: false,
            isOpen_7: false,
            isOpen_8: false,
            isOpen_9: false,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggle(index) {
        this.setState({ isOpen_1: false });
        this.setState({ isOpen_2: false });
        this.setState({ isOpen_3: false });
        this.setState({ isOpen_4: false });
        this.setState({ isOpen_5: false });
        this.setState({ isOpen_6: false });
        this.setState({ isOpen_7: false });
        this.setState({ isOpen_8: false });
        this.setState({ isOpen_9: false });

        switch(index) {
            case 1:
                this.setState({ isOpen_1: !this.state.isOpen_1 });
                break;
            case 2:
                this.setState({ isOpen_2: !this.state.isOpen_2 });
                break;
            case 3:
                this.setState({ isOpen_3: !this.state.isOpen_3 });
                break;
            case 4:
                this.setState({ isOpen_4: !this.state.isOpen_4 });
                break;
            case 5:
                this.setState({ isOpen_5: !this.state.isOpen_5 });
                break;
            case 6:
                this.setState({ isOpen_6: !this.state.isOpen_6 });
                break;
            case 7:
                this.setState({ isOpen_7: !this.state.isOpen_7 });
                break;
            case 8:
                this.setState({ isOpen_8: !this.state.isOpen_8 });
                break;
            case 9:
                this.setState({ isOpen_9: !this.state.isOpen_9 });
                break;
        }
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="faq" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>F.A.Q.</h2>

                    <div className="item">
                        <Button onClick={ () => this.toggle(1) }>How long will it take to get my carport delivered after I place my order?</Button>
                        {/* <Collapse isOpen={this.state.isOpen_1}>
                            <div>
                                <p>The mass majority of orders are installed within two to eight weeks, and is rare for a building to take more time to be delivered and installed. While we make every effort to install thousands of building each year, there are many times were installation can take longer than normal. Timing your installation can depend on many factors, including but not limited to; your location from interstate corridors or large cities, holidays or special events, and severe weather.</p>
                                <p>For a better estimated delivery and installation time frame, call our office toll free 888-8-CARPORTS (888-822-7767).</p>
                            </div>
                        </Collapse> */}

                        <Collapse isOpen={this.state.isOpen_1}>
                            <Card>
                                <CardBody>
                                    <p>The mass majority of orders are installed within two to eight weeks, and is rare for a building to take more time to be delivered and installed. While we make every effort to install thousands of building each year, there are many times were installation can take longer than normal. Timing your installation can depend on many factors, including but not limited to; your location from interstate corridors or large cities, holidays or special events, and severe weather.</p>
                                    <p>For a better estimated delivery and installation time frame, call our office toll free 888-8-CARPORTS (888-822-7767).</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(2) }>Can I save money by buying my carport directly from T-N-T Carports, Inc. main office?</Button>
                        <Collapse isOpen={this.state.isOpen_2}>
                            <Card>
                                <CardBody>
                                    <p>No, T-N-T Carports, Inc. is very loyal to their many dealers and feel that you will better understand our products if you buy them directly from one of their dealers.  All T-N-T Carports, Inc. dealer's have the same retail pricing.  The only thing you should consider is the source you are buying your product from...  We are the online industry leader, as our logo states, "Your trusted source, covering all your needs." - Carports.com!</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(3) }>I'm pouring the cement slab for my carport. What size to I need to make it?</Button>
                        <Collapse isOpen={this.state.isOpen_3}>
                            <Card>
                                <CardBody>
                                    <p>The slab should be 3/4" wider and 1' shorter in length.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(4) }>What are the dimensions of the carport, outside to outside?</Button>
                        <Collapse isOpen={this.state.isOpen_4}>
                            <Card>
                                <CardBody>
                                    <p>We measure out carports in Width x Length x Height. Example: 18 x 21 x 6
Length dimension is measured by the roof metal having a 6" overhang on each end of the bottom base rail is one foot shorter than the roof. Width is measured from the outer edge of one base railacross to the other outer edge of the base rail.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(5) }>What is the highest leg height I can order?</Button>
                        <Collapse isOpen={this.state.isOpen_5}>
                            <Card>
                                <CardBody>
                                    <p>Generally the highest leg height we provide is 12', anything higher can be ordered with special approval.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(6) }>What margin of adjustment will I have to level my carport?</Button>
                        <Collapse isOpen={this.state.isOpen_6}>
                            <Card>
                                <CardBody>
                                    <p>You will have about 3 to 4 inches to adjust with.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(7) }>Can I add options to my carport after it has been installed?</Button>
                        <Collapse isOpen={this.state.isOpen_7}>
                            <Card>
                                <CardBody>
                                    <p>In some situations. We only provide materials for T-N-T Carports, Inc. products. For all "after deliver and installation" additions, there is a $75 service charge. If you are adding walk-in or garage doors your carport must have been installed with legs taller than 6'.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(8) }>What types of anchors are used by T-N-T Carports, Inc.?</Button>
                        <Collapse isOpen={this.state.isOpen_8}>
                            <Card>
                                <CardBody>
                                    <p>We use three different types of anchors:  A:)  3' rebar anchor for ground and asphalt installations.  B:)  Double helix mobile home anchors for an additional charge or standard with certified carports on ground installations only.  C:) Concrete anchors for concrete installations.  Ask about it, 888-822-7767 nationwide.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>

                    <div className="item">
                        <Button onClick={ () => this.toggle(9) }>What is the pitch in the center of the carport?</Button>
                        <Collapse isOpen={this.state.isOpen_9}>
                            <Card>
                                <CardBody>
                                    <p>The pitch varies depending on the width of the carport. With the majority of our products it could range from 2 1/2' to 4'.</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                </div>
            </main>
        );
    }
}

export default Faq;
