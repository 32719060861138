import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import 'font-awesome/css/font-awesome.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

import galleryData from '../../assets/data/gallery.json';
import productCategories from '../../assets/data/product_categories.json';
import states from '../../assets/data/states.json';

import slider_1 from '../../assets/images/Carports/Image 44.webp';
import left_arrow from '../../assets/images/left_arrow.webp';
import right_arrow from '../../assets/images/right_arrow.webp';
import OnlineBuild from '../common/OnlineBuild.js';

import tntlogo from '../../assets/images/Disclaimer/tnt-carports.webp';
import tntlogofb from '../../assets/imagesSlow/Disclaimer/tnt-carports.bmp';
import tntMetalLogo from '../../assets/images/Disclaimer/tnt-metal-buildings.webp';
import tntMetalLogofb from '../../assets/imagesSlow/Disclaimer/tnt-metal-buildings.bmp';
import triLogo from '../../assets/images/Disclaimer/tristate.webp';
import triLogofb from '../../assets/imagesSlow/Disclaimer/tristate.bmp';
import westLogo from '../../assets/images/Disclaimer/westcoast.webp';
import westLogofb from '../../assets/imagesSlow/Disclaimer/westcoast.bmp';
import amerLogo from '../../assets/images/Disclaimer/american-steel-carports.webp';
import amerLogofb from '../../assets/imagesSlow/Disclaimer/american-steel-carports.bmp';
import coastLogo from '../../assets/images/Disclaimer/coast-to-coast.webp';
import coastLogofb from '../../assets/imagesSlow/Disclaimer/coast-to-coast.bmp';
import interLogo from '../../assets/images/Disclaimer/interstate.webp';
import interLogofb from '../../assets/imagesSlow/Disclaimer/interstate.bmp';
import ten from '../../assets/images/Coupons/10PercentOff.webp';
import tenfb from '../../assets/imagesSlow/Coupons/10PercentOff.jpg';
import door from '../../assets/images/Coupons/doors.webp';
import doorfb from '../../assets/imagesSlow/Coupons/doors.jpg';
import five from '../../assets/images/Coupons/5PercentOff.webp';
import fivefb from '../../assets/imagesSlow/Coupons/5PercentOff.jpg';
import five2 from '../../assets/images/Coupons/5PercentOffOther.webp';
import five2fb from '../../assets/imagesSlow/Coupons/5PercentOffOther.jpg';
import trim from '../../assets/images/Coupons/carports-coupon-matching-trim.webp';
import trimfb from '../../assets/imagesSlow/Coupons/carports-coupon-matching-trim.jpg';




const sortedGalleryData = [];
 
class Coupons extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            state : "North Carolina",
            buildModal: false,
            buildFName: "",
            buildLName: "",
            buildPhone: "",
            buildEmail: "",
            buildState: "",
            isAgreeBuild: true,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    change_state(event) {
        if( event.target.value != "" ) {
            window.onlineBuildModal = true;
            window.state = event.target.value;
            this.setState({ state4Render: true });
        }
    }

    render() {
        // Using The Selected State
        let state = "";
        if( window.state )
            state = window.state;
        else 
            state = this.state.state;

        return (
            <main id="Disclaimer" className="docs">
                <div className="container mt-30 mb-50">
                    <h2>Available Coupons</h2>

                    <div>
                        <p>Print these coupons and use them when you place your order at Carports.com.</p>

                        <h5 className="color-red">Coupons Are Below... For Pricing Online Select Your State:</h5>
                        
                        <div className="state-div align-center">
                            <select onChange={ (e) => this.change_state(e) }>
                                <option value="">-- Select Your State --</option>
                                {
                                    states.map((item, i) => {
                                        return (
                                            <option key={`key-${i}`} value={ item[0] } >
                                                { item[0] }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        

                        <p>Place your order with little as 10% down and pay the remaining balance when your structure has been installed (10% down does not apply to special order). Call today toll free 888-822-7767 (888-8-CARPORTS).</p>

                        <h5><mark>Please call to lock in your order while using these coupons.</mark></h5>

                        <div className="images">
                            
                        </div>
                       
                        <div className="coupon-images">
                            <ReactImageFallback src={ ten } fallbackImage={ tenfb } initialImage="loader.gif" alt="10% off coupon"/>
                            <ReactImageFallback src={ door } fallbackImage={ doorfb } initialImage="loader.gif" alt="Free Walk-in Door Coupon"/>
                            <ReactImageFallback src={ five } fallbackImage={ fivefb } initialImage="loader.gif" alt="5% Off Coupon"/>
                             <ReactImageFallback src={ five2 } fallbackImage={ five2fb } initialImage="loader.gif" alt="5% Off Coupon"/>
                            <ReactImageFallback src={ trim } fallbackImage={ trimfb } initialImage="loader.gif" alt="1 Walk-in Door Coupon"/>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <h5 className="color-red"><i>Why Carports.com?</i></h5>

                        <p>We cover more than 150,000,000 square feet (every year) with metal carports, garages, horse barns and other outdoor covers.  We have any option you're looking for, along with the name and brand you can trust.  When you order a product that will last for years, you want a company that will be there for you, support the product you buy and stand behind it.  You can count on Carports.com and our friendly staff to provide A+ service.</p>

                        <ul>
                            <li>Meet our happy customers!</li>
                            <li>Meet & contact our friendly staff.</li>
                            <li>View videos of our structures online.</li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Sizes Available:</i></h5>

                        <p className="mb-0"><b>Carports & Covers:</b> 7' to 40' Wide</p>
                        <p className="pl-50">Up to 12' Tall, Any Length!</p>

                        <p className="mb-0"><b>Garages & Buildings:</b> 12' to 40' Wide</p>
                        <p className="pl-50">Up to 16' Tall, Any Length!</p>

                        <p><b>Got A Custom Project?</b> We work with thousands of customers, each project is different.  Leave it to our experts to assist you in finding what will fit your needs.  Call us, we specialize in custom built outdoor covers.</p>
                    </div>

                    <div>
                        <h5 className="color-red"><i>Kits or Installation?</i></h5>

                        <p><b>You pick!</b> We carry both options for you, depending on what your need is, which brand your shopping for, etc.</p>
                    </div>

                    <div className="brands">
                        <h5 className="color-red"><i>Featured Brands...</i></h5>

                       <div className="align-center">
                            <ReactImageFallback src={ tntlogo } fallbackImage={tntlogofb} initialImage="loader.gif" alt="Tnt logo" />
                            <ReactImageFallback src={ tntMetalLogo } fallbackImage={tntMetalLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ triLogo } fallbackImage={triLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                             <ReactImageFallback src={ westLogo } fallbackImage={westLogofb} initialImage="loader.gif" alt="West Coast carports logo" />
                      
                        </div>

                        <div className="align-center">
                            <ReactImageFallback src={ amerLogo } fallbackImage={amerLogofb} initialImage="loader.gif" alt="American Steel logo" />
                            <ReactImageFallback src={ coastLogo } fallbackImage={coastLogofb} initialImage="loader.gif" alt="Tnt metal logo" />
                            <ReactImageFallback src={ interLogo } fallbackImage={interLogofb} initialImage="loader.gif" alt="Tristate carports logo" />
                            
                        </div>
                    </div>	

                </div>

                <OnlineBuild />
            </main>
        );
    }
}

export default Coupons;
