import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { createStore } from 'redux';
import combinedReducers from './reducers/index.js';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
// 



const store = createStore( combinedReducers );

const app = <BrowserRouter>
        <App />
    </BrowserRouter>

class Setup extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <App />
            </BrowserRouter>
        )
    }
}



ReactDOM.render(<Setup />, document.getElementById('root'));



serviceWorker.register();